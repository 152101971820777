export const scrollToIdHelper = (id: string) => {
  let el = document.getElementById(id);

  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
