import Axios, { AxiosInstance } from "axios";
import urlJoin from "url-join";
import { IContactFormSubmitRequest } from "./api-interfaces/contact-form/IContactFormSubmitRequest";
import BadRequestError from "./api-interfaces/errors/BadRequestError";
import NotFoundError from "./api-interfaces/errors/NotFoundError";
import ApiCommunicationError from "./api-interfaces/errors/ApiCommunicationError";
import { IGetSettingsResponse } from "./api-interfaces/IGetSettingsResponse";
import { IFindRequest } from "./api-interfaces/search-club/IFindRequest";
import { IFindResponse } from "./api-interfaces/search-club/IFindResponse";
import { IInterestFormSubmitRequest } from "./api-interfaces/contact-form/IInterestFormSubmitRequest";
import Settings from "../settings";

export default class Api {
  public static async getSettings(): Promise<IGetSettingsResponse> {
    let client = Api.getAxiosClient();
    let url = Api.buildUrl(`/api/AppSettings/GetSettings`);

    try {
      let response = await client.get<IGetSettingsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get settings');
    }
  }

  public static async submitContactForm(data: IContactFormSubmitRequest): Promise<void> {
    let client = Api.getAxiosClient();
    let url = Api.buildUrl(`/api/ContactFormPage/Submit`);

    try {
      await client.post<void>(url, data);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t submit contact form');
    }
  }

  public static async submitInterestForm(data: IInterestFormSubmitRequest): Promise<void> {
    let client = Api.getAxiosClient();
    let url = Api.buildUrl(`/api/InterestFormPage/Submit`);

    try {
      await client.post<void>(url, data);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t submit interest form');
    }
  }

  public static async findClubs(data: IFindRequest): Promise<IFindResponse> {
    let client = Api.getAxiosClient();
    let url = Api.buildUrl(`/api/MapPage/Find`);

    try {
      let response = await client.post<IFindResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t find clubs by location');
    }
  }

  private static getAxiosClient(): AxiosInstance {
     return Axios.create({
      headers: {
        Accept: 'application/json'
      }
    });
  }

  private static buildUrl(relativePath: string): string {
    const apiUrl = Settings.API_URL;

    return urlJoin(apiUrl, relativePath);
  }

  private static buildCommunicationException(err: any, generalErrorMessage: string): Error {
    if (err.response) {
      if (err.response.status === 400) {
        let content = err.response.data;
        throw new BadRequestError(content);
      }
      if (err.response.status === 404) {
        throw new NotFoundError(err);
      }
    }
    throw new ApiCommunicationError(generalErrorMessage, err);
  }
}
