import moment from "moment";
import React, { Component } from "react";
import { Col, FormCheck, FormSelect, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { IClubInfo } from "../../api/api-interfaces/search-club/IClubInfo";
import { IFindRequest } from "../../api/api-interfaces/search-club/IFindRequest";
import { IGeoCoordinateInfo } from "../../api/api-interfaces/search-club/IGeoCoordinateInfo";
import ClubDetails from "../../components/club-details/ClubDetails";
import CustomButton from "../../components/common/buttons/custom-button/CustomButton";
import ContactFormComponent from "../../components/common/contact-form-component/ContactFormComponent";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import AutoComplete from "../../components/common/google/autocomplete/AutoComplete";
import Map from "../../components/common/google/map/Map";
import Marker from "../../components/common/google/marker/Marker";
import Loader from "../../components/common/loading-bar/loader/Loader";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import {
  CLUB_TYPE_ARRAY,
  ClubTypeInfo,
  DistanceUnitsInfo,
  MeetingDayInfo,
  NumberOfMembersCategoryInfo,
  SearchDistanceCategory,
  SIGNATURE_SERVICE_PROJECTS
} from "../../constants/Constants";
import CookiesHelper from "../../helpers/cookies-helper/CookiesHelper";
import EnumHelper from "../../helpers/enum-helper/EnumHelper";
import ImageHelper from "../../helpers/image-helper/ImageHelper";
import { scrollToIdHelper } from "../../helpers/scroll-into-view-helper/scrollToIdHelper";
import { scrollToTop } from "../../helpers/scroll-into-view-helper/scrollToTop";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import { mapContainerOpacityStyle, mapContainerStyle } from "../../styles/googleMapStyles";
import { loaderStyles } from "../../styles/loaderStyles";
import { ISearchClubProps } from "./ISearchClubProps";
import { ISearchClubState } from "./ISearchClubState";
import styles from "./SearchClub.module.scss";
import { withGeolocation } from "../../hoks/WithGeolocation";
import { v4 as uuid } from 'uuid';
import { isFirefox } from 'react-device-detect';
import { withMediaQuery } from "../../hoks/WithMediaQuery";
import { scrollToIdInContainer } from "../../helpers/scroll-into-view-helper/scrollToIdInContainer";

class SearchClub extends Component<ISearchClubProps, ISearchClubState> {
  private readonly kclScrollContainerId = "kclScrollContainer";

  constructor(props: ISearchClubProps) {
    super(props);

    this.state = {
      selectedClubTypes: [],
      coords: undefined,
      isGeolocationAvailable: true,
      isGeolocationEnabled: true,

      location: undefined,
      showLocationError: false,
      distance: SearchDistanceCategory.Radius20,
      unit: DistanceUnitsInfo.Miles,
      isFindButtonClick: false,

      clubs: [],
      bounds: null,

      isFilterOpen: false,
      selectedFilters: 0,
      meetingDays: [],
      serviceProjectCategory: [],
      selectedServiceProjectCategory: [],
      numberOfMembers: [],

      zoom: 11,
      center: {lat: 0, lng: 0},

      recaptchaSiteKey: "",
      showInterestForm: false,
      clubId: "",
      clubKeyNumber: "",
      clubName: "",
      clubType: undefined,

      isLoading: true,
      excludeKeys: [],
      validationErrors: null,
      navigate: null
    };
  }

  render() {
    const t = this.props.t;

    const {
      isLoading,
      distance,
      unit,
      isFilterOpen,
      selectedFilters,
      meetingDays,
      numberOfMembers
    } = this.state;

    if (this.state.navigate !== null) {
      return <Navigate to={this.state.navigate}/>;
    }

    return (
      <ContainerPageSize>
        {
          this.state.showInterestForm
            ?
            <ContactFormComponent
              googleRecaptchaSiteKey={this.state.recaptchaSiteKey}
              googleRecaptchaActionNameForInterestForm={this.props.googleRecaptchaActionNameForInterestForm}
              clubKeyNumber={this.state.clubKeyNumber}
              clubName={this.state.clubName}
              clubType={this.state.clubType}
              onSubmitClick={() => this.onSubmitClick()}
              onCancelClick={() => this.onCancelClick()}
            />
            :
            <>
              {
                this.state.navigate
                  ? <Navigate to={RoutingConstants.SELECT_CLUB_TYPE}/>
                  :
                  <div className="pt-3">
                    <Row className={'flexThis'}>
                      <Col md={12} lg={6} className="mb-2 mb-lg-0">
                        <AutoComplete id={"kcl-autocomplete"} name={"Location"} size={"sm"}
                                      onPlaceChanged={(latLng) => this.onLocationChanged(latLng)}
                        />
                      </Col>

                      <Col xs={4} lg={2}>
                        <FormSelect name="Distance" size={"sm"} value={SearchDistanceCategory[distance]}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onDistanceChange(e)}>
                          {
                            EnumHelper.enumToKeys(SearchDistanceCategory)
                              .map(key => <option key={"distance_" + key} value={key}>{t(key)}</option>)
                          }
                        </FormSelect>
                      </Col>

                      <Col xs={4} lg={2}>
                        <FormSelect name="Units" size={"sm"} value={DistanceUnitsInfo[unit]}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onUnitChange(e)}>
                          {
                            EnumHelper.enumToKeys(DistanceUnitsInfo)
                              .map(key => <option key={"unit_" + key} value={key}>{t(key)}</option>)
                          }
                        </FormSelect>
                      </Col>

                      <Col xs={4} lg={2} className="text-end">
                        <div className={styles.kclPr12Sm}>
                          <CustomButton buttonType="button" title={t("search_club__find")} size={"sm"}
                                        disabled={(!this.state.location && !this.state.coords) || isLoading}
                                        onClick={() => this.onFindClick(this.state.location ?? this.state.center)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <VerticalSpace size={VerticalSpaceSize.small}/>

                    {
                      <Row>
                        <Col className={`order-1 order-lg-0 ${styles.kclLeftPanel}`}>
                          <>
                            {
                              isFilterOpen
                                ? <>
                                  <div className={`my-2 d-flex justify-content-between`}>
                                    <div className={"kcl-heading-fw-600 align-self-end"} style={{marginTop: "0.7rem"}}>
                                      {t("search_club__filters")}
                                    </div>
                                    <div className={`${isFirefox ? styles.kclMr12 : styles.kclMr}`}>
                                      <CustomButton
                                        title={`${t("search_club__close")}`}
                                        size={"sm"}
                                        buttonType={"button"}
                                        onClick={() => this.onFilterClose()}
                                      />
                                    </div>
                                  </div>
                                </>
                                : <>
                                  <div className={`my-2 d-flex justify-content-start`}>
                                    <CustomButton
                                      title={`${t("search_club__filters")} ${selectedFilters > 0 ? `(${selectedFilters})` : ""}`}
                                      size={"sm"}
                                      buttonType={"button"}
                                      onClick={() => this.onFilterOpen()}
                                    />
                                  </div>
                                </>
                            }
                          </>

                          <div id={this.kclScrollContainerId} className={`${styles.kclLeftPanelScroll}`}>
                            {
                              isFilterOpen &&
                              <>
                                <hr id={"kclFilterTop"}/>
                                <div className={"kcl-heading-fw-600 mb-2"}>{t("search_club__club_types")}</div>

                                <ul className="customUl">
                                  {
                                    CLUB_TYPE_ARRAY.map((club) =>
                                      <FormCheck id={"clubType_" + club.code} key={"clubType_" + club.code}
                                                 type="checkbox"
                                                 checked={this.state.selectedClubTypes.includes(club.code)}
                                                 onChange={() => this.onClubTypeChange(club.code)}
                                                 label={(<>{t(club.code)}</>)}
                                                 className={'kclMtMb'}
                                                 disabled={isLoading}
                                      />
                                    )
                                  }
                                </ul>
                                <hr/>

                                <div className={"kcl-heading-fw-600 mb-2"}>{t("search_club__meeting_days")}</div>
                                <ul className="customUl">
                                  {
                                    EnumHelper.enumToKeys(MeetingDayInfo).map((key) =>
                                      <FormCheck id={"meetingDay_" + key} key={"meetingDay_" + key} type="checkbox"
                                                 checked={meetingDays.includes(MeetingDayInfo[key])}
                                                 onChange={() => this.onMeetingDayChange(MeetingDayInfo[key])}
                                                 label={(<>{t(key)}</>)}
                                                 className={'kclMtMb'}
                                                 disabled={isLoading}
                                      />
                                    )
                                  }
                                </ul>
                                <hr/>

                                <div className={"kcl-heading-fw-600 mb-2"}>
                                  {t("search_club__service_project_category")}
                                </div>

                                <ul className="customUl">
                                  {
                                    this.state.serviceProjectCategory.map((x) =>
                                      (
                                        <FormCheck id={x.label} key={x.label} type="checkbox"
                                                   checked={this.state.selectedServiceProjectCategory.includes(x.label)}
                                                   onChange={() => this.onCategoryChange(x.label)}
                                                   label={(<>{t(x.label)}</>)}
                                                   className={'kclMtMb'}
                                                   disabled={isLoading}
                                        />
                                      )
                                    )
                                  }
                                </ul>
                                <hr/>

                                <div
                                  className={"kcl-heading-fw-600 mb-2"}>{t("search_club__number_of_member_in_a_club")}</div>

                                <ul className="customUl">
                                  {
                                    EnumHelper.enumToKeys(NumberOfMembersCategoryInfo).map((key) =>
                                      (
                                        <FormCheck id={key} key={key} type="checkbox"
                                                   checked={numberOfMembers.includes(NumberOfMembersCategoryInfo[key])}
                                                   onChange={() => this.onNumberOfMembersChange(NumberOfMembersCategoryInfo[key])}
                                                   label={(<>{t(key)}</>)}
                                                   className={'kclMtMb'}
                                                   disabled={isLoading}
                                        />
                                      )
                                    )
                                  }
                                </ul>
                              </>
                            }

                            {
                              !this.state.isFilterOpen && !this.state.showLocationError &&
                              <>
                                {
                                  this.state.clubs.map((clubInfo: IClubInfo, i: number) =>
                                    <ClubDetails
                                      key={"card_" + clubInfo.clubKeyNumber + i}
                                      cardId={clubInfo.clubId}
                                      isSelected={clubInfo.isSelected}
                                      clubInfo={clubInfo}

                                      onCardClick={() => this.onCardClick(clubInfo.clubId, clubInfo.location)}
                                      onContactClick={
                                        () => this.onContactClick(
                                          clubInfo.clubId,
                                          clubInfo.clubKeyNumber,
                                          clubInfo.clubName,
                                          clubInfo.clubType
                                        )}
                                    />
                                  )
                                }

                                {
                                  this.state.clubs.length === 0 && !this.state.showLocationError &&
                                  <div className={"text-center"}>{t("search_club__no_clubs_found")}</div>
                                }
                              </>
                            }

                            {
                              !this.state.isFilterOpen && this.state.showLocationError &&
                              <div>
                                <div>{t("search_club__sorry")}</div>
                                <div>{t("search_club__location_is_not_found")}</div>
                              </div>
                            }
                          </div>
                        </Col>

                        <Col className={
                          `order-0 order-lg-1 text-center ${
                            this.state.coords || (this.state.location && this.state.isFindButtonClick)
                              ? styles.containerStyle
                              : styles.containerStyleWhenNoLocation
                          }`
                        }
                        >
                          {
                            !this.state.isGeolocationAvailable
                              ? <div>{t('search_club__not_support_geolocation')}</div>
                              : (!this.state.isGeolocationEnabled && !this.state.location)
                                ? <div>{t('search_club__please_search_location_above')}</div>
                                : (this.state.coords || this.state.location)
                                  ?
                                  <>
                                    {
                                      isLoading && <Loader style={loaderStyles}/>
                                    }
                                    <Map bounds={this.state.bounds}
                                         center={this.state.center}
                                         zoom={this.state.zoom}
                                         gestureHandling={"greedy"}
                                         controlSize={32}
                                         style={isLoading ? mapContainerOpacityStyle : mapContainerStyle}
                                         disableDefaultUI={true}
                                         zoomControl={true}
                                         isFractionalZoomEnabled={true}
                                         streetViewControl={false}
                                         scaleControl={false}
                                         fullscreenControl={false}
                                         panControl={false}
                                         onIdle={(map) => this.onIdle(map)}
                                    >
                                      {
                                        this.state.clubs.map((club: IClubInfo, i: number) =>
                                          <Marker key={"marker_" + club.clubKeyNumber + i}
                                                  position={club.location}
                                                  icon={ImageHelper.getGpsPointerByClubType(club.clubType)}
                                                  onMarkerClick={() => this.onMarkerClick(club.clubId, club.location)}
                                                  isSelected={club.isSelected}
                                          />
                                        )
                                      }
                                    </Map>

                                  </>
                                  : <div>{t("search_club__getting_location_data")}&hellip; </div>
                          }
                        </Col>
                      </Row>
                    }
                  </div>
              }
            </>
        }
      </ContainerPageSize>
    );
  }

  async componentDidMount() {
    setTimeout(() => {
      scrollToTop();
    }, 300);

    const selectedClubTypes: string[] = JSON.parse(CookiesHelper.getSelectedClubs() ?? '[]');

    let state = {...this.state};

    if (selectedClubTypes.length > 0) {
      state.selectedClubTypes = selectedClubTypes;
    } else {
      state.navigate = RoutingConstants.SELECT_CLUB_TYPE;
    }

    state.recaptchaSiteKey = this.props.googleRecaptchaSiteKey;
    state.serviceProjectCategory = SIGNATURE_SERVICE_PROJECTS;
    state.isLoading = false;

    this.setState(state);

    this.calculateFilters(
      state.selectedClubTypes?.length,
      state.meetingDays?.length,
      state.selectedServiceProjectCategory?.length,
      state.numberOfMembers?.length
    );
  }

  async componentDidUpdate(prevProps: ISearchClubProps) {
    if (prevProps.isGeolocationEnabled !== this.props.isGeolocationEnabled) {
      this.setState({isGeolocationEnabled: this.props.isGeolocationEnabled});
    }

    if (prevProps.isGeolocationAvailable !== this.props.isGeolocationAvailable) {
      this.setState({isGeolocationAvailable: this.props.isGeolocationAvailable});
    }

    if (prevProps.coords !== this.props.coords) {
      if (this.props.coords && !this.state.location) {
        const currentLocation = {
          lat: this.props.coords.latitude,
          lng: this.props.coords.longitude
        };

        this.setState({
          coords: this.props.coords,
          center: currentLocation,
          location: currentLocation
        });

        await this.onFindClick(currentLocation, undefined, undefined, undefined);
      }
    }
  }

  private async onLocationChanged(latLng: IGeoCoordinateInfo | undefined) {
    if (latLng) {
      let location = {lat: latLng.lat, lng: latLng.lng};
      this.setState({location: location});

      await this.onFindClick(
        location,
        this.state.selectedClubTypes,
        this.state.meetingDays,
        this.state.selectedServiceProjectCategory,
        this.state.numberOfMembers
      );
    } else {
      this.setState({isFilterOpen: false, showLocationError: true});
    }
  }

  private async onDistanceChange(e: React.ChangeEvent<HTMLSelectElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'Distance');
    let distance = Number(SearchDistanceCategory[e.target.value as keyof typeof SearchDistanceCategory]);
    await this.setState({distance: distance});

    await this.onFindClick(
      this.state.location,
      this.state.selectedClubTypes,
      this.state.meetingDays,
      this.state.selectedServiceProjectCategory,
      this.state.numberOfMembers
    );
  }

  private async onUnitChange(e: React.ChangeEvent<HTMLSelectElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'Units');
    await this.setState({unit: DistanceUnitsInfo[e.target.value as keyof typeof DistanceUnitsInfo]});

    await this.onFindClick(
      this.state.location,
      this.state.selectedClubTypes,
      this.state.meetingDays,
      this.state.selectedServiceProjectCategory,
      this.state.numberOfMembers
    );
  }

  private async onFindClick(
    searchLocation?: IGeoCoordinateInfo,
    filteredClubTypes?: string[],
    filteredMeetingDays?: MeetingDayInfo[],
    filteredServiceProjectCategories?: string[],
    filteredCategories?: NumberOfMembersCategoryInfo[]
  ) {
    await this.setState({
      isLoading: true,
      isFindButtonClick: true,
      showLocationError: false
    });

    let request: IFindRequest = {
      searchLocation: searchLocation ? searchLocation : this.state.location ?? this.state.center,
      distanceCategory: this.state.distance,
      distanceUnits: this.state.unit,
      meetingDays: filteredMeetingDays ? filteredMeetingDays : this.state.meetingDays,
      serviceProjectCategories: filteredServiceProjectCategories ? filteredServiceProjectCategories : this.state.selectedServiceProjectCategory,
      numberOfMemberCategories: filteredCategories ? filteredCategories : this.state.numberOfMembers,
      clubTypes: filteredClubTypes ? filteredClubTypes : this.state.selectedClubTypes
    };

    try {
      let response = await Api.findClubs(request);

      let clubs: IClubInfo[] = [];
      response.clubs.forEach(x => {
        clubs.push({
            clubId: uuid(),
            location: x.location,
            addressCity: x.addressCity,
            addressCountry: x.addressCountry,
            addressPostalCode: x.addressPostalCode,
            addressState: x.addressState,
            addressStreet: x.addressStreet,
            clubKeyNumber: x.clubKeyNumber,
            clubName: x.clubName,
            clubType: x.clubType,
            distance: x.distance,
            distanceUnits: x.distanceUnits,
            isSelected: x.isSelected,
            meetingPlace: x.meetingPlace,
            meetingTimeAndDay: x.meetingTimeAndDay,
            numberOfMembers: x.numberOfMembers,
            signatureProject: x.signatureProject,
            website: x.website
          }
        );
      });


      this.setState({clubs: clubs});

      if (response.clubs && response.clubs.length > 0) {
        let bounds = new google.maps.LatLngBounds();
        response.clubs.forEach((club) => {
          const position = new google.maps.LatLng(club.location.lat, club.location.lng);
          bounds.extend(position);
        });

        this.setState({bounds: bounds});
      } else {
        this.setState({bounds: null});
      }

      if (searchLocation) {
        this.setState({center: searchLocation});
      }

      this.setState({isLoading: false});
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors(this.props.t("search_club__can_not_find_a_club"), err));
    }
  }

  private async onClubTypeChange(code: string) {
    let selectedClubTypes = [...this.state.selectedClubTypes];

    if (selectedClubTypes.includes(code)) {
      let index = selectedClubTypes.indexOf(code);
      selectedClubTypes.splice(index, 1);
    } else {
      selectedClubTypes.push(code);
    }

    this.setState({selectedClubTypes: selectedClubTypes});

    CookiesHelper.removeSelectedClubs();

    let expiresSelectedClubs = moment().add(1, 'day').toDate();
    let selectedClubsJsonString = JSON.stringify(selectedClubTypes ? selectedClubTypes : '[]');
    CookiesHelper.setSelectedClubs(selectedClubsJsonString, expiresSelectedClubs);

    if (this.state.coords || this.state.location) {
      await this.onFindClick(undefined, selectedClubTypes);
    }
  };

  private async onMeetingDayChange(key: MeetingDayInfo) {
    let selectedDaysCopy = [...this.state.meetingDays];

    if (selectedDaysCopy.includes(key)) {
      let index = selectedDaysCopy.indexOf(key);
      selectedDaysCopy.splice(index, 1);
    } else {
      selectedDaysCopy.push(key);
    }

    this.setState({meetingDays: selectedDaysCopy});

    if (this.state.coords || this.state.location) {
      await this.onFindClick(undefined, undefined, selectedDaysCopy);
    }
  };

  private async onCategoryChange(code: string) {
    let category = [...this.state.selectedServiceProjectCategory];

    if (category.includes(code)) {
      let index = category.indexOf(code);
      category.splice(index, 1);
    } else {
      category.push(code);
    }

    this.setState({selectedServiceProjectCategory: category});

    if (this.state.coords || this.state.location) {
      await this.onFindClick(
        undefined,
        undefined,
        undefined,
        category
      );
    }
  };

  private async onNumberOfMembersChange(key: NumberOfMembersCategoryInfo) {
    let selectedNumberOfMembersCopy = [...this.state.numberOfMembers];

    if (selectedNumberOfMembersCopy.includes(key)) {
      let index = selectedNumberOfMembersCopy.indexOf(key);
      selectedNumberOfMembersCopy.splice(index, 1);
    } else {
      selectedNumberOfMembersCopy.push(key);
    }

    this.setState({numberOfMembers: selectedNumberOfMembersCopy});

    if (this.state.coords || this.state.location) {
      await this.onFindClick(
        undefined,
        undefined,
        undefined,
        undefined,
        selectedNumberOfMembersCopy);
    }
  };

  private onFilterClose() {
    let state = {...this.state};
    this.calculateFilters(
      state.selectedClubTypes?.length,
      state.meetingDays?.length,
      state.selectedServiceProjectCategory?.length,
      state.numberOfMembers?.length
    );

    this.setState({isFilterOpen: false});
  }

  private onFilterOpen() {
    this.setState({isFilterOpen: !this.state.isFilterOpen});

    if (typeof window !== 'undefined') {
      const matches = window.matchMedia('(min-width: 992px)').matches;
      if (matches) {
        setTimeout(() => {
          scrollToIdHelper("kclFilterTop");
        }, 300);
      }
    }
  }

  private calculateFilters(
    selectedClubTypes: number | undefined,
    meetingDaysLength: number | undefined,
    selectedServiceProjectCategoryLength: number | undefined,
    numberOfMembersLength: number | undefined
  ) {
    let cl = selectedClubTypes !== undefined
      ? selectedClubTypes
      : this.state.selectedClubTypes.length;

    let ml = meetingDaysLength !== undefined
      ? meetingDaysLength
      : this.state.meetingDays.length;

    let sl = selectedServiceProjectCategoryLength !== undefined
      ? selectedServiceProjectCategoryLength
      : this.state.selectedServiceProjectCategory.length;

    let nl = numberOfMembersLength !== undefined
      ? numberOfMembersLength
      : this.state.numberOfMembers.length;

    let selectedFilters: number = cl + ml + sl + nl;

    this.setState({selectedFilters: selectedFilters});
  }

  private onCardClick(id: string, location: IGeoCoordinateInfo) {
    let clubInfos = [...this.state.clubs];
    clubInfos.forEach((x) => {
      x.isSelected = x.clubId === id;
    });

    this.setState({
      clubs: clubInfos,
      center: location,
      zoom: parseInt(CookiesHelper.getZoom() ?? "11"),
      bounds: null
    });

    setTimeout(() => {
      scrollToTop();
    }, 300);
  }

  private onContactClick(clubId: string, clubKeyNumber: string, clubName: string, clubType: ClubTypeInfo) {
    this.setState({
      clubId: clubId,
      clubKeyNumber: clubKeyNumber,
      clubName: clubName,
      clubType: clubType,
      showInterestForm: true
    });
  }

  private onMarkerClick(clubId: string, location: IGeoCoordinateInfo) {
    this.onFilterClose();

    let clubInfos = [...this.state.clubs];
    clubInfos.forEach((x) => {
      x.isSelected = (x.location.lat === location.lat) && (x.location.lng === location.lng);
    });

    let zoom = Number(CookiesHelper.getZoom() ?? "11");
    let centerFromCookie = CookiesHelper.getCenter();
    let center = centerFromCookie ? JSON.parse(centerFromCookie) : location;

    this.setState({
      clubs: clubInfos,
      center: center,
      zoom: zoom,
      bounds: null
    });

    setTimeout(() => {
      let firstClubByLocation = clubInfos.find(x => (x.location.lat === location.lat) && (x.location.lng === location.lng));
      let elementId = firstClubByLocation ? firstClubByLocation.clubId : clubId;

      if (this.props.minWidth992px) {
        scrollToIdInContainer(this.kclScrollContainerId, elementId);
      } else {
        scrollToIdHelper(elementId);
      }
    }, 800);
  }

  private onIdle(map: google.maps.Map) {
    let expires = moment().add(1, 'day').toDate();
    let zoom: number = map.getZoom()!;
    CookiesHelper.setZoom(zoom.toString(), expires);

    const mapCenter = map.getCenter()!;
    if (mapCenter) {
      let center: google.maps.LatLngLiteral = {lat: mapCenter.lat(), lng: mapCenter.lng()};
      CookiesHelper.setCenter(JSON.stringify(center), expires);
    }
  }

  private onSubmitClick() {
    this.hideInterestFormAndScrollToClub();
  }

  private onCancelClick() {
    this.hideInterestFormAndScrollToClub();
  }

  private hideInterestFormAndScrollToClub() {
    this.setState({showInterestForm: false});

    setTimeout(() => {
      scrollToIdHelper(this.state.clubId);
    }, 800);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);

    if (this.state.validationErrors) {
      let render = Validations.getValidationSummary(this.state.validationErrors, this.state.excludeKeys);

      if (render) {
        const customId = "contact-form-custom-id";
        toast.error(render, {toastId: customId});
      }
    }
  }
}

export default withTranslation()(withGeolocation(withMediaQuery(SearchClub)));

