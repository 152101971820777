export default class ApiCommunicationError extends Error {
  public cause: any;

  constructor(message: string, cause: any) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);

    this.cause = cause;
  }
}