import React, { Component } from 'react';
import { ICountryDropdownProps } from "./ICountryDropdownProps";
import { FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import RequiredField from "../common/required-field/RequiredField";
import ICountry from "../../constants/countries/ICountry";

class CountryDropdown extends Component<ICountryDropdownProps> {

  constructor(props: ICountryDropdownProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {t, name} = this.props;

    function customCountrySorting() {
      return function (a: ICountry, b: ICountry) {
        return t(a.iso2Code, {ns: 'countries'}).localeCompare(t(b.iso2Code, {ns: 'countries'}));
      };
    }

    return (
      <>
        <FormGroup controlId="country-select">
          <FormLabel>{t('contact_form__country')}:<RequiredField/></FormLabel>
          <FormSelect name={name}
                      size={this.props.size}
                      value={this.props.selectedCountryIso2Code}
                      onChange={this.onCountryChange.bind(this)}>
            {
              this.props.countries
                .sort(customCountrySorting())
                .map(x =>
                  <option key={x.id} value={x.iso2Code}>
                    {t(x.iso2Code, {ns: 'countries'})}
                  </option>
                )
            }
          </FormSelect>
        </FormGroup>
      </>
    );
  }

  onCountryChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (this.props.onCountryChange) {
      this.props.onCountryChange(e.target.value);
    }
  }
}

export default withTranslation(['translation', 'countries'])(CountryDropdown);