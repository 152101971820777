type Enum = { [s: number]: string };

export default class EnumHelper {
  // 1. Check if a key is property of enum
  public static isEnumKey<T extends Enum>(enumSrc: T, key: unknown): key is keyof T {
    return Number.isInteger(enumSrc[key as keyof T]);
  }

  // 2. Check if enum has a given value
  public static isEnumValue<T extends Enum>(enumSrc: T, value: unknown): value is T[keyof T] {
    return Number.isInteger(enumSrc[enumSrc[value as keyof T] as any as keyof T]);
  }

  // 3. Transform enum to list of keys
  public static enumToKeys<T extends Enum>(enumSrc: T): (keyof T)[] {
    return Object.keys(enumSrc)
      .filter((key: keyof T | any) => this.isEnumKey(enumSrc, key)) as (keyof T)[];
  }

  // 4. Transform enum to list of values
  public static enumToValues<T extends Enum>(enumSrc: T): T[keyof T][] {
    return this.enumToKeys(enumSrc)
      .map((key: keyof T) => enumSrc[key]);
  }

  // 5. Transform enum value to its appropriate key
  public static enumValueToKey<T extends Enum>(enumSrc: T, value: T[keyof T]): keyof T | undefined {
    return (enumSrc as any)[value];
  }

  // 6. Transform enum to entries
  public static enumToEntries<T extends Enum>(enumSrc: T): [keyof T, T[keyof T]][] {
    return this.enumToValues(enumSrc)
      .map((value: T[keyof T]) =>
        [this.enumValueToKey(enumSrc, value) as keyof T, value]);
  }

  // 7. Project the list of objects from an enum
  public static fromEnum<T extends Enum, C>(
    enumSrc: T,
    projection: (item: [keyof T, T[keyof T]], index: number, array: [keyof T, T[keyof T]][]) => C,
    skip?: (value: [keyof T, T[keyof T]], index: number, array: [keyof T, T[keyof T]][]) => boolean
  ) {
    let entries = this.enumToEntries(enumSrc);

    if (skip) entries = entries.filter(skip);

    return entries.map(projection);
  }
}