import * as Cookies from 'es-cookie';
import ISimpleEvent from '../events/ISimpleEvent';
import SimpleEvent from '../events/SimpleEvent';

export default class CookiesHelper {
  private static readonly SelectedClubs: string = 'SelectedClubs';
  private static readonly GoogleMapZoom: string = 'GoogleMapZoom';
  private static readonly GoogleMapCenter: string = 'GoogleMapCenter';

  private static readonly onChangeEventDispatcher = new SimpleEvent<void>();


  public static setSelectedClubs(selectedClubs: string, expirationSeconds: Date): void {
    Cookies.set(CookiesHelper.SelectedClubs, selectedClubs, {expires: expirationSeconds});
  }

  public static getSelectedClubs(): string | undefined {
    return Cookies.get(CookiesHelper.SelectedClubs);
  }

  public static removeSelectedClubs() {
    Cookies.remove(this.SelectedClubs);
  }


  public static setZoom(zoom: string, expirationSeconds: Date): void {
    Cookies.set(CookiesHelper.GoogleMapZoom, zoom, {expires: expirationSeconds});
  }

  public static getZoom(): string | undefined {
    return Cookies.get(CookiesHelper.GoogleMapZoom);
  }

  public static removeZoom() {
    Cookies.remove(this.GoogleMapZoom);
  }


  public static setCenter(center: string, expirationSeconds: Date): void {
    Cookies.set(CookiesHelper.GoogleMapCenter, center, {expires: expirationSeconds});
  }

  public static getCenter(): string | undefined {
    return Cookies.get(CookiesHelper.GoogleMapCenter);
  }

  public static removeCenter() {
    Cookies.remove(this.GoogleMapCenter);
  }



  public static get onChange(): ISimpleEvent<void> {
    return this.onChangeEventDispatcher;
  }
}
