import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ICustomLinkProps } from "./ICustomLinkProps";
import s from "./CustomLink.module.scss";

class CustomLink extends Component<ICustomLinkProps> {

  render() {
    let styles = this.props.styles;

    return (
      <Link to={this.props.to}
            className={s.customLinkColor}
            style={{...styles}}
            onClick={this.onClickHandler.bind(this)}
            target={this.props.target}
      >
        {this.props.children}
      </Link>
    );
  }

  private onClickHandler() {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  }
}

export default CustomLink;