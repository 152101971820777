export const INTEREST_FORM_PATH = "interest-form";

export default class RoutingConstants {
  public static HOME: string = '/';

  /* All routes except of "HOME" should have common prefix "/ki-cl/" to make it easier to define
     URL rewrite rules on web server where the app will be hosted
  */

  public static CONTACT_FORM: string = '/ki-cl/contact-form';
  public static SELECT_CLUB_TYPE: string = '/ki-cl/select-club-type';
  public static SEARCH_CLUB: string = '/ki-cl/search-club';
}

