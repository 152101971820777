import { ClubTypeInfo } from "../../constants/Constants";

import gps_pointer_key from "../../img/search-club/gps_pointer_key.png";
import gps_pointer_builders from "../../img/search-club/gps_pointer_builders.png";
import gps_pointer_aktion from "../../img/search-club/gps_pointer_aktion.png";
import gps_pointer_cki from "../../img/search-club/gps_pointer_cki.png";
import gps_pointer_k_kids from "../../img/search-club/gps_pointer_k-kids.png";
import gps_pointer_kiwanis from "../../img/search-club/gps_pointer_kiwanis.png";

import key_logo_left_panel from "../../img/search-club/club-icons/key_logo_left_panel.svg";
import builders_logo_left_panel from "../../img/search-club/club-icons/builders_logo_left_panel.svg";
import action_logo_left_panel from "../../img/search-club/club-icons/action_logo_left_panel.svg";
import cki_logo_left_panel from "../../img/search-club/club-icons/cki_logo_left_panel.svg";
import kids_logo_left_panel from "../../img/search-club/club-icons/kids_logo_left_panel.svg";
import kiwasis_logo_left_panel from "../../img/search-club/club-icons/kiwanis_logo_left_panel.svg";

export default class ImageHelper {
  public static getGpsPointerByClubType(clubType: ClubTypeInfo): string | undefined {
    switch (Number(ClubTypeInfo[clubType])) {
      case ClubTypeInfo.KeyClub:
        return gps_pointer_key;
      case ClubTypeInfo.BuildersClub:
        return gps_pointer_builders;
      case ClubTypeInfo.Aktion:
        return gps_pointer_aktion;
      case ClubTypeInfo.Cki:
        return gps_pointer_cki;
      case ClubTypeInfo.KKids:
        return gps_pointer_k_kids;
      case ClubTypeInfo.Kiwanis:
        return gps_pointer_kiwanis;
      default:
        throw new Error(`Non-existent clubType in switch: ${clubType}`);
    }
  }

  public static getClubTypeImageByClubType(clubType: ClubTypeInfo): string | undefined {
    switch (Number(ClubTypeInfo[clubType])) {
      case ClubTypeInfo.KeyClub:
        return key_logo_left_panel;
      case ClubTypeInfo.BuildersClub:
        return builders_logo_left_panel;
      case ClubTypeInfo.Aktion:
        return action_logo_left_panel;
      case ClubTypeInfo.Cki:
        return cki_logo_left_panel;
      case ClubTypeInfo.KKids:
        return kids_logo_left_panel;
      case ClubTypeInfo.Kiwanis:
        return kiwasis_logo_left_panel;
      default:
        throw new Error(`Non-existent clubType in switch: ${clubType}`);
    }
  }
}
