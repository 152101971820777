import { IState } from "../IState";

export const ISO_US_STATE_LIST: IState[] = [
  {
    englishShortName: "Armed Forces Americas",
    iso2Code: "AA",
    iso3166_2Code: "US-AA"
  },
  {
    englishShortName: "Armed Forces Europe",
    iso2Code: "AE",
    iso3166_2Code: "US-AE"
  },
  {
    englishShortName: "Alaska",
    iso2Code: "AK",
    iso3166_2Code: "US-AK"
  },
  {
    englishShortName: "Alabama",
    iso2Code: "AL",
    iso3166_2Code: "US-AL"
  },
  {
    englishShortName: "Armed Forces Pacific",
    iso2Code: "AP",
    iso3166_2Code: "US-AP"
  },
  {
    englishShortName: "Arkansas",
    iso2Code: "AR",
    iso3166_2Code: "US-AR"
  },
  {
    englishShortName: "American Samoa",
    iso2Code: "AS",
    iso3166_2Code: "US-AS"
  },
  {
    englishShortName: "Arizona",
    iso2Code: "AZ",
    iso3166_2Code: "US-AZ"
  },
  {
    englishShortName: "California",
    iso2Code: "CA",
    iso3166_2Code: "US-CA"
  },
  {
    englishShortName: "Colorado",
    iso2Code: "CO",
    iso3166_2Code: "US-CO"
  },
  {
    englishShortName: "Connecticut",
    iso2Code: "CT",
    iso3166_2Code: "US-CT"
  },
  {
    englishShortName: "District of Columbia",
    iso2Code: "DC",
    iso3166_2Code: "US-DC"
  },
  {
    englishShortName: "Delaware",
    iso2Code: "DE",
    iso3166_2Code: "US-DE"
  },
  {
    englishShortName: "Florida",
    iso2Code: "FL",
    iso3166_2Code: "US-FL"
  },
  {
    englishShortName: "Federated Micronesia",
    iso2Code: "FM",
    iso3166_2Code: "US-FM"
  },
  {
    englishShortName: "Georgia",
    iso2Code: "GA",
    iso3166_2Code: "US-GA"
  },
  {
    englishShortName: "Guam",
    iso2Code: "GU",
    iso3166_2Code: "US-GU"
  },
  {
    englishShortName: "Hawaii",
    iso2Code: "HI",
    iso3166_2Code: "US-HI"
  },
  {
    englishShortName: "Iowa",
    iso2Code: "IA",
    iso3166_2Code: "US-IA"
  },
  {
    englishShortName: "Idaho",
    iso2Code: "ID",
    iso3166_2Code: "US-ID"
  },
  {
    englishShortName: "Illinois",
    iso2Code: "IL",
    iso3166_2Code: "US-IL"
  },
  {
    englishShortName: "Indiana",
    iso2Code: "IN",
    iso3166_2Code: "US-IN"
  },
  {
    englishShortName: "Kansas",
    iso2Code: "KS",
    iso3166_2Code: "US-KS"
  },
  {
    englishShortName: "Kentucky",
    iso2Code: "KY",
    iso3166_2Code: "US-KY"
  },
  {
    englishShortName: "Louisiana",
    iso2Code: "LA",
    iso3166_2Code: "US-LA"
  },
  {
    englishShortName: "Massachusetts",
    iso2Code: "MA",
    iso3166_2Code: "US-MA"
  },
  {
    englishShortName: "Maryland",
    iso2Code: "MD",
    iso3166_2Code: "US-MD"
  },
  {
    englishShortName: "Maine",
    iso2Code: "ME",
    iso3166_2Code: "US-ME"
  },
  {
    englishShortName: "Marshall Islands",
    iso2Code: "MH",
    iso3166_2Code: "US-MH"
  },
  {
    englishShortName: "Michigan",
    iso2Code: "MI",
    iso3166_2Code: "US-MI"
  },
  {
    englishShortName: "Minnesota",
    iso2Code: "MN",
    iso3166_2Code: "US-MN"
  },
  {
    englishShortName: "Missouri",
    iso2Code: "MO",
    iso3166_2Code: "US-MO"
  },
  {
    englishShortName: "Northern Mariana Islands",
    iso2Code: "MP",
    iso3166_2Code: "US-MP"
  },
  {
    englishShortName: "Mississippi",
    iso2Code: "MS",
    iso3166_2Code: "US-MS"
  },
  {
    englishShortName: "Montana",
    iso2Code: "MT",
    iso3166_2Code: "US-MT"
  },
  {
    englishShortName: "North Carolina",
    iso2Code: "NC",
    iso3166_2Code: "US-NC"
  },
  {
    englishShortName: "North Dakota",
    iso2Code: "ND",
    iso3166_2Code: "US-ND"
  },
  {
    englishShortName: "Nebraska",
    iso2Code: "NE",
    iso3166_2Code: "US-NE"
  },
  {
    englishShortName: "New Hampshire",
    iso2Code: "NH",
    iso3166_2Code: "US-NH"
  },
  {
    englishShortName: "New Jersey",
    iso2Code: "NJ",
    iso3166_2Code: "US-NJ"
  },
  {
    englishShortName: "New Mexico",
    iso2Code: "NM",
    iso3166_2Code: "US-NM"
  },
  {
    englishShortName: "Nevada",
    iso2Code: "NV",
    iso3166_2Code: "US-NV"
  },
  {
    englishShortName: "New York",
    iso2Code: "NY",
    iso3166_2Code: "US-NY"
  },
  {
    englishShortName: "Ohio",
    iso2Code: "OH",
    iso3166_2Code: "US-OH"
  },
  {
    englishShortName: "Oklahoma",
    iso2Code: "OK",
    iso3166_2Code: "US-OK"
  },
  {
    englishShortName: "Oregon",
    iso2Code: "OR",
    iso3166_2Code: "US-OR"
  },
  {
    englishShortName: "Pennsylvania",
    iso2Code: "PA",
    iso3166_2Code: "US-PA"
  },
  {
    englishShortName: "Puerto Rico",
    iso2Code: "PR",
    iso3166_2Code: "US-PR"
  },
  {
    englishShortName: "Palau",
    iso2Code: "PW",
    iso3166_2Code: "US-PW"
  },
  {
    englishShortName: "Rhode Island",
    iso2Code: "RI",
    iso3166_2Code: "US-RI"
  },
  {
    englishShortName: "South Carolina",
    iso2Code: "SC",
    iso3166_2Code: "US-SC"
  },
  {
    englishShortName: "South Dakota",
    iso2Code: "SD",
    iso3166_2Code: "US-SD"
  },
  {
    englishShortName: "Tennessee",
    iso2Code: "TN",
    iso3166_2Code: "US-TN"
  },
  {
    englishShortName: "Texas",
    iso2Code: "TX",
    iso3166_2Code: "US-TX"
  },
  {
    englishShortName: "United States Minor Outlying Islands",
    iso2Code: "UM",
    iso3166_2Code: "US-UM"
  },
  {
    englishShortName: "Utah",
    iso2Code: "UT",
    iso3166_2Code: "US-UT"
  },
  {
    englishShortName: "Virginia",
    iso2Code: "VA",
    iso3166_2Code: "US-VA"
  },
  {
    englishShortName: "Virgin Islands",
    iso2Code: "VI",
    iso3166_2Code: "US-VI"
  },
  {
    englishShortName: "Vermont",
    iso2Code: "VT",
    iso3166_2Code: "US-VT"
  },
  {
    englishShortName: "Washington",
    iso2Code: "WA",
    iso3166_2Code: "US-WA"
  },
  {
    englishShortName: "Wisconsin",
    iso2Code: "WI",
    iso3166_2Code: "US-WI"
  },
  {
    englishShortName: "West Virginia",
    iso2Code: "WV",
    iso3166_2Code: "US-WV"
  },
  {
    englishShortName: "Wyoming",
    iso2Code: "WY",
    iso3166_2Code: "US-WY"
  }
];
