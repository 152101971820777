export const scrollToIdInContainer = (containerId: string, elementId: string) => {
  let container = document.getElementById(containerId);
  let element = document.getElementById(elementId);

  if (container && element) {
    container.scrollTo({
      top: element?.offsetTop - container?.offsetTop,
      behavior: 'smooth',
    });
  }
};
