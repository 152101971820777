import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { ICustomButtonProps } from './ICustomButtonProps';

export default class CustomButton extends Component<ICustomButtonProps> {

  constructor(props: Readonly<ICustomButtonProps>) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  render() {
    return (
      <>
        <Button id={this.props.id}
                className={this.props.className}
                style={this.props.styles}
                type={this.props.buttonType}
                size={this.props.size}
                variant={this.props.variant}
                disabled={this.props.disabled}
                onClick={this.onClickHandler}
        >
          {
            <>
              <span className="align-baseline">
                {
                  this.props.isLoading &&
                  <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"/>
                }
                {this.props.title}
              </span>
            </>
          }
        </Button>
      </>
    );
  }

  private onClickHandler() {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  }
}
