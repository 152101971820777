import React from "react";
import { Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import kiwanisLogoWhiteImg from "../../img/header/kiwasis_logo_white.png";
import LanguageSwitcher from "../language-switcher/LanguageSwitcher";
import "./Header.scss";

const Header: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <Navbar id={"kclNavbar"} bg="light" expand="lg" className={"kclNavbar"}>
        <div className={"container mb-1 pt-3 align-items-end px-3"}>
          <Navbar.Brand href="#/" className={"kclLeftDistance"}>
            <img src={kiwanisLogoWhiteImg} alt="kiwanis_logo" className="d-inline-block align-top pr-3"/>
          </Navbar.Brand>
          <div className={`kclNavbarBrand d-none d-lg-block mb-1`}>{t("app_name")}</div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className={"mb-1 custom-toggler"}
                         style={{boxShadow: 'none'}}/>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <div className="d-flex justify-content-between">
              <div className={`kclNavbarBrand d-block d-lg-none`}>{t("app_name")}</div>
              <div className={"kclRightDistance"}>
                <LanguageSwitcher/>
              </div>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
