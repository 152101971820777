import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DistanceUnitsInfo, KIWANIS_BLUE_COLOR } from "../../constants/Constants";
import ImageHelper from "../../helpers/image-helper/ImageHelper";
import CustomButton from "../common/buttons/custom-button/CustomButton";
import styles from "./ClubDetails.module.scss";
import { IClubDetailsProps } from "./IClubDetailsProps";

const ClubDetails: React.FC<IClubDetailsProps> = (
  {
    cardId,
    isSelected,
    clubInfo,
    onCardClick,
    onContactClick
  }) => {
  const {t} = useTranslation();

  return (
    <>
      <Card onClick={onCardClick} id={cardId}>
        <Card.Body className={isSelected ? `${styles.selectedClub}` : `${styles.kclCardPadding}`}>

          <div className="d-flex justify-content-between align-items-top mb-2">
            <div className="d-flex">
              <div style={{paddingRight: "10px"}}>
                <img src={ImageHelper.getGpsPointerByClubType(clubInfo.clubType)} height={"48px"}
                     alt={`marker ${clubInfo.clubType}`} style={{padding: 0, maxWidth: "none"}}/>
              </div>
              <div>
                <img src={ImageHelper.getClubTypeImageByClubType(clubInfo.clubType)} height={"20px"}
                     alt={`club type ${clubInfo.clubType}`} style={{padding: 0, display: "inline-block"}}/>
                <div className={`${styles.kclClubInfo} mt-2 mb-2 text-break`}>
                  {
                    (clubInfo.clubName || clubInfo.clubKeyNumber) &&
                    <div>
                      {clubInfo.clubName} {clubInfo.clubKeyNumber ? (clubInfo.clubKeyNumber) : ""}
                    </div>
                  }
                </div>
              </div>
            </div>

            {
              clubInfo.clubKeyNumber && clubInfo.clubName &&
              <div className="text-end">
                <CustomButton buttonType={"button"} title={t("search_club__contact")} size={"sm"}
                              onClick={onContactClick}
                />
              </div>
            }
          </div>
          <Card.Text className={"text-break"}>
            {
              clubInfo.numberOfMembers !== null &&
              <>
                <span className={styles.kclCardLabel}>
                  {t("search_club__number_of_members")}
                </span>: {clubInfo.numberOfMembers} <br/>
              </>
            }

            {
              clubInfo.meetingPlace &&
              <>
               <span className={styles.kclCardLabel}>{t("search_club__meet_at")}</span>: {clubInfo.meetingPlace} <br/>
              </>
            }

            {
              (clubInfo.addressStreet || clubInfo.addressCity || clubInfo.addressState || clubInfo.addressCountry || clubInfo.addressPostalCode) &&
              <>
                {clubInfo.addressStreet} {clubInfo.addressCity} {clubInfo.addressState} {clubInfo.addressCountry} {clubInfo.addressPostalCode} <br/>
              </>
            }

            {
              clubInfo.distance !== null &&
              <>
                <span className={styles.kclCardLabel}>{t("search_club__distance")}</span>: {clubInfo.distance.toFixed(2)} {
                DistanceUnitsInfo[clubInfo.distanceUnits].toString() === DistanceUnitsInfo.Km.toString()
                  ? t(`club_details__km`)
                  : t(`club_details__mi`)
              } <br/>
              </>
            }

            {
              clubInfo.meetingTimeAndDay &&
              <>
                <span className={styles.kclCardLabel}>
                  {t("search_club__meeting_time_day")}
                </span>: {clubInfo.meetingTimeAndDay} <br/>
              </>
            }

            {
              clubInfo.website &&
              <>
                <span className={styles.kclCardLabel}>{t("search_club__club_web_site")}</span>:
                <span> </span>
                <a style={{color: KIWANIS_BLUE_COLOR}}
                   href={clubInfo.website}
                   target={"_blank"}
                   rel="noreferrer"
                   className={"text-break"}
                >
                  {clubInfo.website} <br/>
                </a>
              </>
            }

            {
              clubInfo.signatureProject &&
              <>
                <span className={styles.kclCardLabel}>
                  {t("search_club__signature_service_project")}
                </span>: {t(`${clubInfo.signatureProject}`)} <br/>
              </>
            }
          </Card.Text>

        </Card.Body>
      </Card>
    </>
  );
};

export default ClubDetails;
