import moment from "moment";
import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import CustomButton from "../../components/common/buttons/custom-button/CustomButton";
import { CLUB_TYPE_ARRAY, CLUB_TYPE_ARRAY_FOR_MOBILE_VIEW } from "../../constants/Constants";
import CookiesHelper from "../../helpers/cookies-helper/CookiesHelper";
import { scrollToTop } from "../../helpers/scroll-into-view-helper/scrollToTop";
import RoutingConstants from "../../routes/RoutingConstants";
import { ISelectClubTypeProps } from "./ISelectClubTypeProps";
import { ISelectClubTypeState } from "./ISelectClubTypeState";
import ClubType from "../../components/club-type/ClubType";
import styles from "./SelectClubType.module.scss";
import { withMediaQuery } from "../../hoks/WithMediaQuery";

class SelectClubType extends Component<ISelectClubTypeProps, ISelectClubTypeState> {
  constructor(props: ISelectClubTypeProps) {
    super(props);

    this.state = {
      selectedClubs: [],
      navigate: null
    };
  }

  render() {
    const t = this.props.t;

    if (this.state.navigate !== null) {
      return <Navigate to={this.state.navigate}/>;
    }


    return (
      <Container fluid className={`${styles.kclContainer} pb-5`}>
        <Row className="justify-content-between flex-md-row kclRowAfter" style={{content: "none"}}>
          <h6 className="kcl-fw-600 text-center mt-3 mb-3">{t("select_club_type__select_club")}</h6>
          {
            this.props.minWidth768px
              ? CLUB_TYPE_ARRAY.map((club, index) => {
                  return (
                    <ClubType key={club.code + index}
                              cardId={club.code + index}
                              clubType={club.code}
                              isSelected={this.state.selectedClubs.includes(club.code)}
                              onCardClick={() => this.onSelectClubClick(club.code)}
                    />
                  );
                }
              )
              : CLUB_TYPE_ARRAY_FOR_MOBILE_VIEW.map((club, index) => {
                  return (
                    <ClubType key={club.code + index}
                              cardId={club.code + index}
                              clubType={club.code}
                              isSelected={this.state.selectedClubs.includes(club.code)}
                              onCardClick={() => this.onSelectClubClick(club.code)}
                    />
                  );
                }
              )
          }
        </Row>

        <div className="text-center">
          <CustomButton buttonType={"button"} variant="primary" size={"sm"}
                        title={t('select_club_type__find_clubs')}
                        disabled={this.state.selectedClubs.length < 1}
                        onClick={this.onFindClubsClick.bind(this)}
          />
        </div>
      </Container>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      scrollToTop();
    }, 300);

    const selectedClubTypes: string[] = JSON.parse(CookiesHelper.getSelectedClubs() ?? '[]');

    if (selectedClubTypes.length > 0) {
      this.setState({selectedClubs: selectedClubTypes});
    }
  }

  private onFindClubsClick() {
    this.setState({navigate: RoutingConstants.SEARCH_CLUB});
  }

  private onSelectClubClick(code: string) {
    let selectedClubs = [...this.state.selectedClubs];

    if (selectedClubs.includes(code)) {
      let index = selectedClubs.indexOf(code);
      selectedClubs.splice(index, 1);
    } else {
      selectedClubs.push(code);
    }

    this.setState({selectedClubs: selectedClubs});

    CookiesHelper.removeSelectedClubs();

    let expiresSelectedClubs = moment().add(1, 'day').toDate();
    let selectedClubsJsonString = JSON.stringify(selectedClubs ? selectedClubs : '[]');
    CookiesHelper.setSelectedClubs(selectedClubsJsonString, expiresSelectedClubs);
  }
}

export default withTranslation()(withMediaQuery(SelectClubType));
