import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import ReactModal from 'react-modal';
import CustomButton from "../../buttons/custom-button/CustomButton";
import HtmlHelper from "../../../../helpers/html-helper/HtmlHelper";
import styles from '../../../../styles/ModalWindow.module.scss';
import { ISubmitContactFormNotificationModalProps } from "./ISubmitContactFormNotificationModalProps";

const SubmitContactFormNotificationModal: React.FC<ISubmitContactFormNotificationModalProps> = (
  {
    showDialog,
    onConfirm
  }) => {
  const {t} = useTranslation();
  const rootDivElement = HtmlHelper.getRootDivElement();

  let dialogIndex: number = 100;
  const [dialogId] = useState<string>('submit_contact_form_dialog_' + (dialogIndex++));

  return (
    <ReactModal isOpen={showDialog}
                id={dialogId}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                className={`${styles.content}`}
                overlayClassName={`${styles.overlay}`}
                appElement={rootDivElement}
                parentSelector={() => rootDivElement}
                ariaHideApp={false}
    >
      <div className="kiwanis-club-locator">
        <h3>{t("contact_form__submit_contact_form")}</h3>

        <p>{t("contact_form__contact_form_has_been_submitted")}</p>

        <div className="d-flex justify-content-end">
          <CustomButton variant="primary" styles={{borderRadius: 0}}
                        className="px-4"
                        title={t("contact_form__ok")}
                        onClick={onConfirm}
          />
        </div>
      </div>

    </ReactModal>
  );
};

export default SubmitContactFormNotificationModal;
