import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomLink from "../../components/common/custom-link/CustomLink";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import { LANGUAGE_CODE } from "../../constants/Constants";
import { scrollToTop } from "../../helpers/scroll-into-view-helper/scrollToTop";
import useMediaQuery from "../../hooks/useMediaQuery";
import hand_en from "../../img/home-page/hand_lens_EN_700.png";
import hand_de from "../../img/home-page/hand_lens_DE_700.png";
import hand_es from "../../img/home-page/hand_lens_ES_700.png";
import hand_fr from "../../img/home-page/hand_lens_FR_700.png";
import hand_it from "../../img/home-page/hand_lens_IT_700.png";
import hand_nl from "../../img/home-page/hand_lens_NL_700.png";
import hand_ja from "../../img/home-page/hand_lens_JA_700.png";
import hand_zh_CHT from "../../img/home-page/hand_lens_ZH_CHT_700.png";
import lens_en from "../../img/home-page/new_lens_EN_400px.png";
import lens_de from "../../img/home-page/new_lenz_DE_400px.png";
import lens_es from "../../img/home-page/new_lenz_ES_400px.png";
import lens_fr from "../../img/home-page/new_lenz_FR_400px.png";
import lens_it from "../../img/home-page/new_lenz_IT_400px.png";
import lens_nl from "../../img/home-page/new_lenz_NL_400px.png";
import lens_ja from "../../img/home-page/new_lenz_JA_400px.png";
import lens_zh_CHT from "../../img/home-page/new_lenz_ZH_CHT_400px.png";
import RoutingConstants from "../../routes/RoutingConstants";
import styles from "./Home.module.scss";

const Home: React.FC = () => {
  const {t, i18n} = useTranslation();

  const matches = useMediaQuery('(min-width: 576px)');

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 300);
  }, []);

  function getHandImageByLanguage() {
    switch (i18n.language) {
      case LANGUAGE_CODE.en:
        return hand_en;
      case LANGUAGE_CODE.de:
        return hand_de;
      case LANGUAGE_CODE.es:
        return hand_es;
      case LANGUAGE_CODE.fr:
        return hand_fr;
      case LANGUAGE_CODE.it:
        return hand_it;
      case LANGUAGE_CODE.nl:
        return hand_nl;
      case LANGUAGE_CODE.ja:
        return hand_ja;
      case LANGUAGE_CODE.zh_CHT:
        return hand_zh_CHT;
      default:
        return hand_en;
    }
  }

  function getLensImageByLanguage() {
    switch (i18n.language) {
      case LANGUAGE_CODE.en:
        return lens_en;
      case LANGUAGE_CODE.de:
        return lens_de;
      case LANGUAGE_CODE.es:
        return lens_es;
      case LANGUAGE_CODE.fr:
        return lens_fr;
      case LANGUAGE_CODE.it:
        return lens_it;
      case LANGUAGE_CODE.nl:
        return lens_nl;
      case LANGUAGE_CODE.ja:
        return lens_ja;
      case LANGUAGE_CODE.zh_CHT:
        return lens_zh_CHT;
      default:
        return lens_en;
    }
  }

  return (
    <Container fluid style={{paddingLeft: 0}}>
      <VerticalSpace size={VerticalSpaceSize.normal}/>

      <div className={"kclWrapper"} style={{position: "relative"}}>
        <div className={styles.kclLensCover}>
          <CustomLink to={RoutingConstants.SELECT_CLUB_TYPE}>
            {
              matches
                ? <img src={getHandImageByLanguage()} alt="hand_with_lens" style={{zIndex: 10}}/>
                : <img src={getLensImageByLanguage()} alt="lens_find_club" style={{zIndex: 10}}/>
            }
          </CustomLink>
        </div>

        <div className={"container-md kclContainer"}>
          <Row className={'flexThis'}>
            <Col xs={6} lg={5} xxl={4}/>
            <Col xs={6} lg={7} xxl={8}>
              <div className={`${styles.kclDescription} kclDescriptionWithLinks`}>
                <div className="kclDescriptionContainer">
                  <p className={`${styles.textIndent} mb-1 mb-sm-4`}>{t("home_page__description")}</p>

                  <Row>
                    <Col xxl={2}>
                      <p className={`${styles.textIndent} mb-2`}>{t("home_page__i_want_to")}</p>
                    </Col>
                    <Col>
                      <div className="d-flex justify-content-end kclLinkContainer">
                        <div className={`${styles.containerLinkStyle} text-break d-none d-md-block pb-5`}>
                          <div>
                            <CustomLink to={RoutingConstants.CONTACT_FORM}
                                        styles={{textDecoration: "none", lineHeight: "1.5"}}>
                              <div className={`${styles.linkStyle} px-4 mt-2`}>
                                {t("home_page__contact_form")}
                              </div>
                            </CustomLink>
                          </div>
                          <div>
                            <CustomLink to={RoutingConstants.SELECT_CLUB_TYPE}
                                        styles={{textDecoration: "none", lineHeight: "1.5"}}>
                              <div className={`${styles.linkStyle} px-4 mt-2`}>
                                {t("home_page__find_a_club_near_me")}
                              </div>
                            </CustomLink>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="d-flex justify-content-end d-block d-md-none pb-5 kclMdLinkContainer"
             style={{marginLeft: "12px"}}>
          <div className={`${styles.containerLinkStyle} text-break`}>
            <div>
              <CustomLink to={RoutingConstants.CONTACT_FORM} styles={{textDecoration: "none"}}>
                <div className={`${styles.linkStyle} px-4 mt-2`}>
                  {t("home_page__contact_form")}
                </div>
              </CustomLink>
            </div>
            <div>
              <CustomLink to={RoutingConstants.SELECT_CLUB_TYPE} styles={{textDecoration: "none"}}>
                <div className={`${styles.linkStyle} px-4 mt-2`}>
                  {t("home_page__find_a_club_near_me")}
                </div>
              </CustomLink>
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <div className={`container kclMapWrapper`}>
              <div className={`${styles.kclMapCover}`}/>
            </div>
          </Col>
        </Row>
      </div>

    </Container>
  );
};

export default Home;
