import React from "react";
import { useTranslation } from "react-i18next";
import { ValidationSummaryProps } from "./ValidationSummaryProps";

const ValidationSummary: React.FC<ValidationSummaryProps> = ({matchingMessages}) => {
  const {t} = useTranslation();

  return (
    <div>
      <ul className="m-auto customUl">
        {matchingMessages.map((x, i) => <li key={i}>{`${t(x)}`}</li>)}
      </ul>
    </div>
  );
};

export default ValidationSummary;
