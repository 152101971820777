import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.browser';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "./services/i18n";

const rootElement = document.getElementById('club-locator-public-ui') as HTMLElement;
const pageBaseNameAttribute = rootElement === null ? null : rootElement.getAttribute("data-page-location");
let pageBaseName = pageBaseNameAttribute === null ? undefined : pageBaseNameAttribute;

const showHeaderAttribute = rootElement === null ? null : rootElement.getAttribute("data-show-header");
let showHeader = showHeaderAttribute === null ? undefined : showHeaderAttribute;

const languageAttribute = rootElement === null ? null : rootElement.getAttribute("data-language");
let language = languageAttribute === null ? undefined : languageAttribute;
export const LanguageContext = React.createContext(language);

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <div className="kiwanis-club-locator">
      <I18nextProvider i18n={i18n}>
        <LanguageContext.Provider value={language}>
          <App pageBaseName={pageBaseName} showHeader={showHeader === "true"}/>
        </LanguageContext.Provider>
      </I18nextProvider>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
