import { IState } from "../IState";

export const ISO_AU_STATE_LIST: IState[] = [
  {
    englishShortName: "New South Wales",
    iso2Code: "NSW",
    iso3166_2Code: "AU-NSW"
  },
  {
    englishShortName: "Victoria",
    iso2Code: "VIC",
    iso3166_2Code: "AU-VIC"
  },
  {
    englishShortName: "Queensland",
    iso2Code: "QLD",
    iso3166_2Code: "AU-QLD"
  },
  {
    englishShortName: "Tasmania",
    iso2Code: "TAS",
    iso3166_2Code: "AU-TAS"
  },
  {
    englishShortName: "South Australia",
    iso2Code: "SA",
    iso3166_2Code: "AU-SA"
  },
  {
    englishShortName: "Western Australia",
    iso2Code: "WA",
    iso3166_2Code: "AU-WA"
  },
  {
    englishShortName: "Northern Territory",
    iso2Code: "NT",
    iso3166_2Code: "AU-NT"
  },
  {
    englishShortName: "Australian Capital Territory",
    iso2Code: "ACT",
    iso3166_2Code: "AU-ACT"
  }
];