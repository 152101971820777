import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import ContactFormComponent from "../../components/common/contact-form-component/ContactFormComponent";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import RoutingConstants from "../../routes/RoutingConstants";
import { IContactFormProps } from "./IContactFormProps";
import { IContactFormState } from "./IContactFormState";

class ContactForm extends Component<IContactFormProps, IContactFormState> {

  constructor(props: IContactFormProps) {
    super(props);

    this.state = {
      recaptchaSiteKey: '',
      navigate: null,
      isLoading: true
    };
  }

  render() {
    if (this.state.navigate !== null) {
      return <Navigate to={this.state.navigate}/>;
    }

    return (
      this.state.isLoading
        ? <LoadingBar/>
        :
        <ContactFormComponent
          googleRecaptchaSiteKey={this.state.recaptchaSiteKey}
          googleRecaptchaActionNameForContactForm={this.props.googleRecaptchaActionNameForContactForm}
          onSubmitClick={() => this.onSubmitClick()}
          onCancelClick={() => this.onCancelClick()}
        />
    );
  }

  async componentDidMount() {
    this.setState({
      isLoading: false,
      recaptchaSiteKey: this.props.googleRecaptchaSiteKey
    });
  }

  onSubmitClick() {
    this.setState({navigate: RoutingConstants.HOME});
  }

  onCancelClick() {
    window.history.back();
  }
}

export default ContactForm;
