export const loadReCaptchaScript = (): HTMLScriptElement => {

  // create the script element and...
  const url = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit";

  const recaptchaScript = document.createElement("script");
  recaptchaScript.id = "kcl-google-recaptcha-v3";
  recaptchaScript.type = "text/javascript";
  recaptchaScript.src = url;
  recaptchaScript.async = true;
  recaptchaScript.defer = true;

  // add it to the DOM
  document.body.appendChild(recaptchaScript);

  return recaptchaScript;
};