import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "react-bootstrap";
import "./LanguageSwitcher.scss";
import { LANGUAGE_ARRAY, LANGUAGE_CODE } from "../../constants/Constants";
import { LanguageContext } from "../../index";

function LanguageSwitcher() {
  const {i18n} = useTranslation();
  const browserLanguage = useContext(LanguageContext);
  const [language, setLanguage] = React.useState<string>(LANGUAGE_CODE.en);

  const changeLanguage = useCallback((lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [i18n]);

  useEffect(() => {
    if (browserLanguage) {
      changeLanguage(browserLanguage);
    }
  }, [browserLanguage, changeLanguage]);

  return (
    <div className={"mb-0 mb-lg-1"}>
      <FormSelect size={"sm"} value={language} onChange={(e) => changeLanguage(e.target.value)}>
        {
          LANGUAGE_ARRAY.map(x => (
            <option key={x.code} value={x.code}>{x.value}</option>
          ))
        }
      </FormSelect>
    </div>
  );
}

export default LanguageSwitcher;
