import React, { Component } from 'react';
import { Container } from "react-bootstrap";
import { IContainerPageSizeProps } from "./IContainerPageSizeProps";

export default class ContainerPageSize extends Component<IContainerPageSizeProps, {}> {
  render() {
    return (
      <Container fluid>
        {this.props.children}
      </Container>
    );
  }
}
