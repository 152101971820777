import React, { Component, createRef } from "react";
import { FormControl } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { IAutoCompleteProps } from "./IAutoCompleteProps";
import { ILatLng } from "../interfaces/ILatLng";

type GooglePlaceResult = google.maps.places.PlaceResult;
type AutocompleteOptions = google.maps.places.AutocompleteOptions;

class AutoComplete extends Component<IAutoCompleteProps> {
  private inputRef = createRef<HTMLInputElement>();

  constructor(props: IAutoCompleteProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {t, id, name, size} = this.props;
    return (
      <FormControl id={id} name={name} type="text" ref={this.inputRef} size={size}
                   placeholder={t('search_club__enter_location') as string}
                   onFocus={() => this.onFocus()}
      />
    );
  }

  componentDidMount() {
    this.getPlaceAutocomplete();
  }

  componentWillUnmount() {
    if (this.inputRef.current) {
      this.inputRef.current.blur();
    }
  }

  getPlaceAutocomplete() {
    const options: AutocompleteOptions = {
      fields: ["geometry"],
      strictBounds: false,
      types: ['geocode']
    };

    if (this.inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(this.inputRef.current, options);

      google.maps.event.clearInstanceListeners(autocomplete);

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place: GooglePlaceResult = autocomplete.getPlace();

        let latitude = place.geometry?.location?.lat();
        let longitude = place.geometry?.location?.lng();

        let latLng: ILatLng | undefined = undefined;
        if (latitude && longitude) {
          latLng = {lat: latitude, lng: longitude};
        }

        this.props.onPlaceChanged(latLng);
      });


      this.inputRef.current.addEventListener('keydown', (e: KeyboardEvent) => {

        // If it's Enter
        if (e.code === "Enter") {

          // Select all Google's dropdown DOM nodes (can be multiple)
          const googleDOMNodes = document.getElementsByClassName('pac-container') as HTMLCollectionOf<HTMLElement>;

          // Check if any of them are visible (using ES6 here for conciseness)
          const googleDOMNodeIsVisible = (
            Array.from(googleDOMNodes).some((node: HTMLElement) => node.offsetParent !== null)
          );

          // If one is visible - preventDefault
          if (googleDOMNodeIsVisible) {
            e.preventDefault();
          }
        }
      });

    }
  }

  onFocus() {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }
  }
}

export default withTranslation()(AutoComplete);
