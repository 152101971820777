import ValidationErrorResponse from "../../../helpers/validation-helper/ValidationErrorResponse";

export default class BadRequestError extends Error {
  public cause: any;
  public validationErrorResponse: ValidationErrorResponse;

  constructor(validationErrorResponse: ValidationErrorResponse, cause?: any) {
    super('Validation error response: ' + JSON.stringify(validationErrorResponse));
    Object.setPrototypeOf(this, new.target.prototype);

    this.cause = cause;
    this.validationErrorResponse = validationErrorResponse;
  }
}
