import React from 'react';
import useMediaQuery from "../hooks/useMediaQuery";

export interface WithMediaQueryProps {
  minWidth992px: boolean;
  minWidth768px: boolean;
}

export function withMediaQuery<Props extends WithMediaQueryProps>(Component: React.ComponentType<Props>) {
  return (props: Omit<Props, keyof WithMediaQueryProps>) => {
    const minWidth992px = useMediaQuery('(min-width: 992px)');
    const minWidth768px = useMediaQuery('(min-width: 768px)');

    return (
      <Component
        {...(props as Props)}
        minWidth992px={minWidth992px}
        minWidth768px={minWidth768px}
      />
    );
  };
}
