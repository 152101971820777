import React, { Component } from 'react';
import { IStateDropdownProps } from "./IStateDropdownProps";
import { FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import RequiredField from "../common/required-field/RequiredField";
import { IState } from "../../constants/states/IState";

class StateDropdown extends Component<IStateDropdownProps> {

  constructor(props: IStateDropdownProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {t, name} = this.props;

    function customStateSorting() {
      return function (a: IState, b: IState) {
        return t(a.iso3166_2Code, {ns: 'states'}).localeCompare(t(b.iso3166_2Code, {ns: 'states'}));
      };
    }

    return (
      <>
        <FormGroup controlId="state-select">
          <FormLabel>{t('contact_form__state')}:<RequiredField/></FormLabel>
          <FormSelect name={name}
                      size={this.props.size}
                      value={!this.props.selectedStateIso2Code ? '' : this.props.selectedStateIso2Code}
                      onChange={this.onStateChange.bind(this)}>
            {
              this.props.defaultOptionLabel &&
              <option key={"default-state"} value={''}>{this.props.defaultOptionLabel}</option>
            }
            {
              this.props.states
                ?.sort(customStateSorting())
                .map(x =>
                  <option key={x.iso3166_2Code} value={x.iso2Code}>
                    {t(x.iso3166_2Code, {ns: 'states'})}
                  </option>
                )
            }
          </FormSelect>
        </FormGroup>
      </>
    );
  }

  onStateChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (this.props.onStateChange) {
      this.props.onStateChange(e.target.value);
    }
  }
}

export default withTranslation(['translation', 'states'])(StateDropdown);
