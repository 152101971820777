import { IState } from "../IState";

export const ISO_CA_STATE_LIST: IState[] = [
  {
    englishShortName: "Alberta",
    iso2Code: "AB",
    iso3166_2Code: "CA-AB"
  },
  {
    englishShortName: "British Columbia",
    iso2Code: "BC",
    iso3166_2Code: "CA-BC"
  },
  {
    englishShortName: "Manitoba",
    iso2Code: "MB",
    iso3166_2Code: "CA-MB"
  },
  {
    englishShortName: "New Brunswick",
    iso2Code: "NB",
    iso3166_2Code: "CA-NB"
  },
  {
    englishShortName: "Newfoundland and Labrador",
    iso2Code: "NL",
    iso3166_2Code: "CA-NL"
  },
  {
    englishShortName: "Northwest Territories",
    iso2Code: "NT",
    iso3166_2Code: "CA-NT"
  },
  {
    englishShortName: "Nova Scotia",
    iso2Code: "NS",
    iso3166_2Code: "CA-NS"
  },
  {
    englishShortName: "Nunavut",
    iso2Code: "NU",
    iso3166_2Code: "CA-NU"
  },
  {
    englishShortName: "Ontario",
    iso2Code: "ON",
    iso3166_2Code: "CA-ON"
  },
  {
    englishShortName: "Prince Edward Island",
    iso2Code: "PE",
    iso3166_2Code: "CA-PE"
  },
  {
    englishShortName: "Quebec",
    iso2Code: "QC",
    iso3166_2Code: "CA-QC"
  },
  {
    englishShortName: "Saskatchewan",
    iso2Code: "SK",
    iso3166_2Code: "CA-SK"
  },
  {
    englishShortName: "Yukon Territory",
    iso2Code: "YT",
    iso3166_2Code: "CA-YT"
  }
];