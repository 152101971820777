import React, { Component } from 'react';
import { IVerticalSpaceProps, VerticalSpaceSize } from './IVerticalSpaceProps';
import styles from './VerticalSpace.module.scss';

export default class VerticalSpace extends Component<IVerticalSpaceProps, {}> {
  public static defaultProps = {
    size: VerticalSpaceSize.normal
  };

  render() {
    switch (this.props.size) {
      case VerticalSpaceSize.xs:
        return <div className={styles.xs}/>;
      case VerticalSpaceSize.small:
        return <div className={styles.small}/>;
      case VerticalSpaceSize.large:
        return <div className={styles.large}/>;
      default:
        return <div className={styles.normal}/>;
    }
  }
}
