import ICountry from "./ICountry";

export const ISO_COUNTRY_LIST: ICountry[] = [
  {
    englishShortName: "Afghanistan",
    iso2Code: "AF",
    iso3Code: "AFG",
    id: 4
  },
  {
    englishShortName: "Aland Islands",
    iso2Code: "AX",
    iso3Code: "ALA",
    id: 248
  },
  {
    englishShortName: "Albania",
    iso2Code: "AL",
    iso3Code: "ALB",
    id: 8
  },
  {
    englishShortName: "Algeria",
    iso2Code: "DZ",
    iso3Code: "DZA",
    id: 12
  },
  {
    englishShortName: "American Samoa",
    iso2Code: "AS",
    iso3Code: "ASM",
    id: 16
  },
  {
    englishShortName: "Andorra",
    iso2Code: "AD",
    iso3Code: "AND",
    id: 20
  },
  {
    englishShortName: "Angola",
    iso2Code: "AO",
    iso3Code: "AGO",
    id: 24
  },
  {
    englishShortName: "Anguilla",
    iso2Code: "AI",
    iso3Code: "AIA",
    id: 660
  },
  {
    englishShortName: "Antarctica",
    iso2Code: "AQ",
    iso3Code: "ATA",
    id: 10
  },
  {
    englishShortName: "Antigua and Barbuda",
    iso2Code: "AG",
    iso3Code: "ATG",
    id: 28
  },
  {
    englishShortName: "Argentina",
    iso2Code: "AR",
    iso3Code: "ARG",
    id: 32
  },
  {
    englishShortName: "Armenia",
    iso2Code: "AM",
    iso3Code: "ARM",
    id: 51
  },
  {
    englishShortName: "Aruba",
    iso2Code: "AW",
    iso3Code: "ABW",
    id: 533
  },
  {
    englishShortName: "Australia",
    iso2Code: "AU",
    iso3Code: "AUS",
    id: 36
  },
  {
    englishShortName: "Austria",
    iso2Code: "AT",
    iso3Code: "AUT",
    id: 40
  },
  {
    englishShortName: "Azerbaijan",
    iso2Code: "AZ",
    iso3Code: "AZE",
    id: 31
  },
  {
    englishShortName: "Bahamas",
    iso2Code: "BS",
    iso3Code: "BHS",
    id: 44
  },
  {
    englishShortName: "Bahrain",
    iso2Code: "BH",
    iso3Code: "BHR",
    id: 48
  },
  {
    englishShortName: "Bangladesh",
    iso2Code: "BD",
    iso3Code: "BGD",
    id: 50
  },
  {
    englishShortName: "Barbados",
    iso2Code: "BB",
    iso3Code: "BRB",
    id: 52
  },
  {
    englishShortName: "Belarus",
    iso2Code: "BY",
    iso3Code: "BLR",
    id: 112
  },
  {
    englishShortName: "Belgium",
    iso2Code: "BE",
    iso3Code: "BEL",
    id: 56
  },
  {
    englishShortName: "Belize",
    iso2Code: "BZ",
    iso3Code: "BLZ",
    id: 84
  },
  {
    englishShortName: "Benin",
    iso2Code: "BJ",
    iso3Code: "BEN",
    id: 204
  },
  {
    englishShortName: "Bermuda",
    iso2Code: "BM",
    iso3Code: "BMU",
    id: 60
  },
  {
    englishShortName: "Bhutan",
    iso2Code: "BT",
    iso3Code: "BTN",
    id: 64
  },
  {
    englishShortName: "Bolivia (Plurinational State of)",
    iso2Code: "BO",
    iso3Code: "BOL",
    id: 68
  },
  {
    englishShortName: "Bonaire, Sint Eustatius and Saba",
    iso2Code: "BQ",
    iso3Code: "BES",
    id: 535
  },
  {
    englishShortName: "Bosnia and Herzegovina",
    iso2Code: "BA",
    iso3Code: "BIH",
    id: 70
  },
  {
    englishShortName: "Botswana",
    iso2Code: "BW",
    iso3Code: "BWA",
    id: 72
  },
  {
    englishShortName: "Bouvet Island",
    iso2Code: "BV",
    iso3Code: "BVT",
    id: 74
  },
  {
    englishShortName: "Brazil",
    iso2Code: "BR",
    iso3Code: "BRA",
    id: 76
  },
  {
    englishShortName: "British Indian Ocean Territory",
    iso2Code: "IO",
    iso3Code: "IOT",
    id: 86
  },
  {
    englishShortName: "Brunei Darussalam",
    iso2Code: "BN",
    iso3Code: "BRN",
    id: 96
  },
  {
    englishShortName: "Bulgaria",
    iso2Code: "BG",
    iso3Code: "BGR",
    id: 100
  },
  {
    englishShortName: "Burkina Faso",
    iso2Code: "BF",
    iso3Code: "BFA",
    id: 854
  },
  {
    englishShortName: "Burundi",
    iso2Code: "BI",
    iso3Code: "BDI",
    id: 108
  },
  {
    englishShortName: "Cape Verde",
    iso2Code: "CV",
    iso3Code: "CPV",
    id: 132
  },
  {
    englishShortName: "Cambodia",
    iso2Code: "KH",
    iso3Code: "KHM",
    id: 116
  },
  {
    englishShortName: "Cameroon",
    iso2Code: "CM",
    iso3Code: "CMR",
    id: 120
  },
  {
    englishShortName: "Canada",
    iso2Code: "CA",
    iso3Code: "CAN",
    id: 124
  },
  {
    englishShortName: "Cayman Islands",
    iso2Code: "KY",
    iso3Code: "CYM",
    id: 136
  },
  {
    englishShortName: "Central African Republic",
    iso2Code: "CF",
    iso3Code: "CAF",
    id: 140
  },
  {
    englishShortName: "Chad",
    iso2Code: "TD",
    iso3Code: "TCD",
    id: 148
  },
  {
    englishShortName: "Chile",
    iso2Code: "CL",
    iso3Code: "CHL",
    id: 152
  },
  {
    englishShortName: "China",
    iso2Code: "CN",
    iso3Code: "CHN",
    id: 156
  },
  {
    englishShortName: "Christmas Island",
    iso2Code: "CX",
    iso3Code: "CXR",
    id: 162
  },
  {
    englishShortName: "Cocos (Keeling) Islands",
    iso2Code: "CC",
    iso3Code: "CCK",
    id: 166
  },
  {
    englishShortName: "Colombia",
    iso2Code: "CO",
    iso3Code: "COL",
    id: 170
  },
  {
    englishShortName: "Comoros",
    iso2Code: "KM",
    iso3Code: "COM",
    id: 174
  },
  {
    englishShortName: "Congo (the Democratic Republic of the)",
    iso2Code: "CD",
    iso3Code: "COD",
    id: 180
  },
  {
    englishShortName: "Congo",
    iso2Code: "CG",
    iso3Code: "COG",
    id: 178
  },
  {
    englishShortName: "Cook Islands",
    iso2Code: "CK",
    iso3Code: "COK",
    id: 184
  },
  {
    englishShortName: "Costa Rica",
    iso2Code: "CR",
    iso3Code: "CRI",
    id: 188
  },
  {
    englishShortName: "Croatia",
    iso2Code: "HR",
    iso3Code: "HRV",
    id: 191
  },
  {
    englishShortName: "Cuba",
    iso2Code: "CU",
    iso3Code: "CUB",
    id: 192
  },
  {
    englishShortName: "Curaçao",
    iso2Code: "CW",
    iso3Code: "CUW",
    id: 531
  },
  {
    englishShortName: "Cyprus",
    iso2Code: "CY",
    iso3Code: "CYP",
    id: 196
  },
  {
    englishShortName: "Czech Republic",
    iso2Code: "CZ",
    iso3Code: "CZE",
    id: 203
  },
  {
    englishShortName: "Cote d'Ivoire",
    iso2Code: "CI",
    iso3Code: "CIV",
    id: 384
  },
  {
    englishShortName: "Denmark",
    iso2Code: "DK",
    iso3Code: "DNK",
    id: 208
  },
  {
    englishShortName: "Djibouti",
    iso2Code: "DJ",
    iso3Code: "DJI",
    id: 262
  },
  {
    englishShortName: "Dominica",
    iso2Code: "DM",
    iso3Code: "DMA",
    id: 212
  },
  {
    englishShortName: "Dominican Republic",
    iso2Code: "DO",
    iso3Code: "DOM",
    id: 214
  },
  {
    englishShortName: "Ecuador",
    iso2Code: "EC",
    iso3Code: "ECU",
    id: 218
  },
  {
    englishShortName: "Egypt",
    iso2Code: "EG",
    iso3Code: "EGY",
    id: 818
  },
  {
    englishShortName: "El Salvador",
    iso2Code: "SV",
    iso3Code: "SLV",
    id: 222
  },
  {
    englishShortName: "Equatorial Guinea",
    iso2Code: "GQ",
    iso3Code: "GNQ",
    id: 226
  },
  {
    englishShortName: "Eritrea",
    iso2Code: "ER",
    iso3Code: "ERI",
    id: 232
  },
  {
    englishShortName: "Estonia",
    iso2Code: "EE",
    iso3Code: "EST",
    id: 233
  },
  {
    englishShortName: "Eswatini",
    iso2Code: "SZ",
    iso3Code: "SWZ",
    id: 748
  },
  {
    englishShortName: "Ethiopia",
    iso2Code: "ET",
    iso3Code: "ETH",
    id: 231
  },
  {
    englishShortName: "Falkland Islands [Malvinas]",
    iso2Code: "FK",
    iso3Code: "FLK",
    id: 238
  },
  {
    englishShortName: "Faroe Islands",
    iso2Code: "FO",
    iso3Code: "FRO",
    id: 234
  },
  {
    englishShortName: "Fiji",
    iso2Code: "FJ",
    iso3Code: "FJI",
    id: 242
  },
  {
    englishShortName: "Finland",
    iso2Code: "FI",
    iso3Code: "FIN",
    id: 246
  },
  {
    englishShortName: "France",
    iso2Code: "FR",
    iso3Code: "FRA",
    id: 250
  },
  {
    englishShortName: "French Guiana",
    iso2Code: "GF",
    iso3Code: "GUF",
    id: 254
  },
  {
    englishShortName: "French Polynesia",
    iso2Code: "PF",
    iso3Code: "PYF",
    id: 258
  },
  {
    englishShortName: "French Southern Territories",
    iso2Code: "TF",
    iso3Code: "ATF",
    id: 260
  },
  {
    englishShortName: "Gabon",
    iso2Code: "GA",
    iso3Code: "GAB",
    id: 266
  },
  {
    englishShortName: "Gambia",
    iso2Code: "GM",
    iso3Code: "GMB",
    id: 270
  },
  {
    englishShortName: "Georgia",
    iso2Code: "GE",
    iso3Code: "GEO",
    id: 268
  },
  {
    englishShortName: "Germany",
    iso2Code: "DE",
    iso3Code: "DEU",
    id: 276
  },
  {
    englishShortName: "Ghana",
    iso2Code: "GH",
    iso3Code: "GHA",
    id: 288
  },
  {
    englishShortName: "Gibraltar",
    iso2Code: "GI",
    iso3Code: "GIB",
    id: 292
  },
  {
    englishShortName: "Greece",
    iso2Code: "GR",
    iso3Code: "GRC",
    id: 300
  },
  {
    englishShortName: "Greenland",
    iso2Code: "GL",
    iso3Code: "GRL",
    id: 304
  },
  {
    englishShortName: "Grenada",
    iso2Code: "GD",
    iso3Code: "GRD",
    id: 308
  },
  {
    englishShortName: "Guadeloupe",
    iso2Code: "GP",
    iso3Code: "GLP",
    id: 312
  },
  {
    englishShortName: "Guam",
    iso2Code: "GU",
    iso3Code: "GUM",
    id: 316
  },
  {
    englishShortName: "Guatemala",
    iso2Code: "GT",
    iso3Code: "GTM",
    id: 320
  },
  {
    englishShortName: "Guernsey",
    iso2Code: "GG",
    iso3Code: "GGY",
    id: 831
  },
  {
    englishShortName: "Guinea",
    iso2Code: "GN",
    iso3Code: "GIN",
    id: 324
  },
  {
    englishShortName: "Guinea-Bissau",
    iso2Code: "GW",
    iso3Code: "GNB",
    id: 624
  },
  {
    englishShortName: "Guyana",
    iso2Code: "GY",
    iso3Code: "GUY",
    id: 328
  },
  {
    englishShortName: "Haiti",
    iso2Code: "HT",
    iso3Code: "HTI",
    id: 332
  },
  {
    englishShortName: "Heard Island and McDonald Islands",
    iso2Code: "HM",
    iso3Code: "HMD",
    id: 334
  },
  {
    englishShortName: "Holy See",
    iso2Code: "VA",
    iso3Code: "VAT",
    id: 336
  },
  {
    englishShortName: "Honduras",
    iso2Code: "HN",
    iso3Code: "HND",
    id: 340
  },
  {
    englishShortName: "Hong Kong",
    iso2Code: "HK",
    iso3Code: "HKG",
    id: 344
  },
  {
    englishShortName: "Hungary",
    iso2Code: "HU",
    iso3Code: "HUN",
    id: 348
  },
  {
    englishShortName: "Iceland",
    iso2Code: "IS",
    iso3Code: "ISL",
    id: 352
  },
  {
    englishShortName: "India",
    iso2Code: "IN",
    iso3Code: "IND",
    id: 356
  },
  {
    englishShortName: "Indonesia",
    iso2Code: "ID",
    iso3Code: "IDN",
    id: 360
  },
  {
    englishShortName: "Iran (Islamic Republic of)",
    iso2Code: "IR",
    iso3Code: "IRN",
    id: 364
  },
  {
    englishShortName: "Iraq",
    iso2Code: "IQ",
    iso3Code: "IRQ",
    id: 368
  },
  {
    englishShortName: "Ireland",
    iso2Code: "IE",
    iso3Code: "IRL",
    id: 372
  },
  {
    englishShortName: "Isle of Man",
    iso2Code: "IM",
    iso3Code: "IMN",
    id: 833
  },
  {
    englishShortName: "Israel",
    iso2Code: "IL",
    iso3Code: "ISR",
    id: 376
  },
  {
    englishShortName: "Italy",
    iso2Code: "IT",
    iso3Code: "ITA",
    id: 380
  },
  {
    englishShortName: "Jamaica",
    iso2Code: "JM",
    iso3Code: "JAM",
    id: 388
  },
  {
    englishShortName: "Japan",
    iso2Code: "JP",
    iso3Code: "JPN",
    id: 392
  },
  {
    englishShortName: "Jersey",
    iso2Code: "JE",
    iso3Code: "JEY",
    id: 832
  },
  {
    englishShortName: "Jordan",
    iso2Code: "JO",
    iso3Code: "JOR",
    id: 400
  },
  {
    englishShortName: "Kazakhstan",
    iso2Code: "KZ",
    iso3Code: "KAZ",
    id: 398
  },
  {
    englishShortName: "Kenya",
    iso2Code: "KE",
    iso3Code: "KEN",
    id: 404
  },
  {
    englishShortName: "Kiribati",
    iso2Code: "KI",
    iso3Code: "KIR",
    id: 296
  },
  {
    englishShortName: "Korea (the Democratic People's Republic of)",
    iso2Code: "KP",
    iso3Code: "PRK",
    id: 408
  },
  {
    englishShortName: "Korea (the Republic of)",
    iso2Code: "KR",
    iso3Code: "KOR",
    id: 410
  },
  {
    englishShortName: "Kuwait",
    iso2Code: "KW",
    iso3Code: "KWT",
    id: 414
  },
  {
    englishShortName: "Kyrgyzstan",
    iso2Code: "KG",
    iso3Code: "KGZ",
    id: 417
  },
  {
    englishShortName: "Lao People's Democratic Republic (the)",
    iso2Code: "LA",
    iso3Code: "LAO",
    id: 418
  },
  {
    englishShortName: "Latvia",
    iso2Code: "LV",
    iso3Code: "LVA",
    id: 428
  },
  {
    englishShortName: "Lebanon",
    iso2Code: "LB",
    iso3Code: "LBN",
    id: 422
  },
  {
    englishShortName: "Lesotho",
    iso2Code: "LS",
    iso3Code: "LSO",
    id: 426
  },
  {
    englishShortName: "Liberia",
    iso2Code: "LR",
    iso3Code: "LBR",
    id: 430
  },
  {
    englishShortName: "Libya",
    iso2Code: "LY",
    iso3Code: "LBY",
    id: 434
  },
  {
    englishShortName: "Liechtenstein",
    iso2Code: "LI",
    iso3Code: "LIE",
    id: 438
  },
  {
    englishShortName: "Lithuania",
    iso2Code: "LT",
    iso3Code: "LTU",
    id: 440
  },
  {
    englishShortName: "Luxembourg",
    iso2Code: "LU",
    iso3Code: "LUX",
    id: 442
  },
  {
    englishShortName: "Macao",
    iso2Code: "MO",
    iso3Code: "MAC",
    id: 446
  },
  {
    englishShortName: "Madagascar",
    iso2Code: "MG",
    iso3Code: "MDG",
    id: 450
  },
  {
    englishShortName: "Malawi",
    iso2Code: "MW",
    iso3Code: "MWI",
    id: 454
  },
  {
    englishShortName: "Malaysia",
    iso2Code: "MY",
    iso3Code: "MYS",
    id: 458
  },
  {
    englishShortName: "Maldives",
    iso2Code: "MV",
    iso3Code: "MDV",
    id: 462
  },
  {
    englishShortName: "Mali",
    iso2Code: "ML",
    iso3Code: "MLI",
    id: 466
  },
  {
    englishShortName: "Malta",
    iso2Code: "MT",
    iso3Code: "MLT",
    id: 470
  },
  {
    englishShortName: "Marshall Islands",
    iso2Code: "MH",
    iso3Code: "MHL",
    id: 584
  },
  {
    englishShortName: "Martinique",
    iso2Code: "MQ",
    iso3Code: "MTQ",
    id: 474
  },
  {
    englishShortName: "Mauritania",
    iso2Code: "MR",
    iso3Code: "MRT",
    id: 478
  },
  {
    englishShortName: "Mauritius",
    iso2Code: "MU",
    iso3Code: "MUS",
    id: 480
  },
  {
    englishShortName: "Mayotte",
    iso2Code: "YT",
    iso3Code: "MYT",
    id: 175
  },
  {
    englishShortName: "Mexico",
    iso2Code: "MX",
    iso3Code: "MEX",
    id: 484
  },
  {
    englishShortName: "Micronesia (Federated States of)",
    iso2Code: "FM",
    iso3Code: "FSM",
    id: 583
  },
  {
    englishShortName: "Moldova (the Republic of)",
    iso2Code: "MD",
    iso3Code: "MDA",
    id: 498
  },
  {
    englishShortName: "Monaco",
    iso2Code: "MC",
    iso3Code: "MCO",
    id: 492
  },
  {
    englishShortName: "Mongolia",
    iso2Code: "MN",
    iso3Code: "MNG",
    id: 496
  },
  {
    englishShortName: "Montenegro",
    iso2Code: "ME",
    iso3Code: "MNE",
    id: 499
  },
  {
    englishShortName: "Montserrat",
    iso2Code: "MS",
    iso3Code: "MSR",
    id: 500
  },
  {
    englishShortName: "Morocco",
    iso2Code: "MA",
    iso3Code: "MAR",
    id: 504
  },
  {
    englishShortName: "Mozambique",
    iso2Code: "MZ",
    iso3Code: "MOZ",
    id: 508
  },
  {
    englishShortName: "Myanmar",
    iso2Code: "MM",
    iso3Code: "MMR",
    id: 104
  },
  {
    englishShortName: "Namibia",
    iso2Code: "NA",
    iso3Code: "NAM",
    id: 516
  },
  {
    englishShortName: "Nauru",
    iso2Code: "NR",
    iso3Code: "NRU",
    id: 520
  },
  {
    englishShortName: "Nepal",
    iso2Code: "NP",
    iso3Code: "NPL",
    id: 524
  },
  {
    englishShortName: "Netherlands",
    iso2Code: "NL",
    iso3Code: "NLD",
    id: 528
  },
  {
    englishShortName: "New Caledonia",
    iso2Code: "NC",
    iso3Code: "NCL",
    id: 540
  },
  {
    englishShortName: "New Zealand",
    iso2Code: "NZ",
    iso3Code: "NZL",
    id: 554
  },
  {
    englishShortName: "Nicaragua",
    iso2Code: "NI",
    iso3Code: "NIC",
    id: 558
  },
  {
    englishShortName: "Niger",
    iso2Code: "NE",
    iso3Code: "NER",
    id: 562
  },
  {
    englishShortName: "Nigeria",
    iso2Code: "NG",
    iso3Code: "NGA",
    id: 566
  },
  {
    englishShortName: "Niue",
    iso2Code: "NU",
    iso3Code: "NIU",
    id: 570
  },
  {
    englishShortName: "Norfolk Island",
    iso2Code: "NF",
    iso3Code: "NFK",
    id: 574
  },
  {
    englishShortName: "North Macedonia",
    iso2Code: "MK",
    iso3Code: "MKD",
    id: 807
  },
  {
    englishShortName: "Northern Mariana Islands (the)",
    iso2Code: "MP",
    iso3Code: "MNP",
    id: 580
  },
  {
    englishShortName: "Norway",
    iso2Code: "NO",
    iso3Code: "NOR",
    id: 578
  },
  {
    englishShortName: "Oman",
    iso2Code: "OM",
    iso3Code: "OMN",
    id: 512
  },
  {
    englishShortName: "Pakistan",
    iso2Code: "PK",
    iso3Code: "PAK",
    id: 586
  },
  {
    englishShortName: "Palau",
    iso2Code: "PW",
    iso3Code: "PLW",
    id: 585
  },
  {
    englishShortName: "Palestine",
    iso2Code: "PS",
    iso3Code: "PSE",
    id: 275
  },
  {
    englishShortName: "Panama",
    iso2Code: "PA",
    iso3Code: "PAN",
    id: 591
  },
  {
    englishShortName: "Papua New Guinea",
    iso2Code: "PG",
    iso3Code: "PNG",
    id: 598
  },
  {
    englishShortName: "Paraguay",
    iso2Code: "PY",
    iso3Code: "PRY",
    id: 600
  },
  {
    englishShortName: "Peru",
    iso2Code: "PE",
    iso3Code: "PER",
    id: 604
  },
  {
    englishShortName: "Philippines (the)",
    iso2Code: "PH",
    iso3Code: "PHL",
    id: 608
  },
  {
    englishShortName: "Pitcairn",
    iso2Code: "PN",
    iso3Code: "PCN",
    id: 612
  },
  {
    englishShortName: "Poland",
    iso2Code: "PL",
    iso3Code: "POL",
    id: 616
  },
  {
    englishShortName: "Portugal",
    iso2Code: "PT",
    iso3Code: "PRT",
    id: 620
  },
  {
    englishShortName: "Puerto Rico",
    iso2Code: "PR",
    iso3Code: "PRI",
    id: 630
  },
  {
    englishShortName: "Qatar",
    iso2Code: "QA",
    iso3Code: "QAT",
    id: 634
  },
  {
    englishShortName: "Romania",
    iso2Code: "RO",
    iso3Code: "ROU",
    id: 642
  },
  {
    englishShortName: "Russian Federation",
    iso2Code: "RU",
    iso3Code: "RUS",
    id: 643
  },
  {
    englishShortName: "Rwanda",
    iso2Code: "RW",
    iso3Code: "RWA",
    id: 646
  },
  {
    englishShortName: "Reunion",
    iso2Code: "RE",
    iso3Code: "REU",
    id: 638
  },
  {
    englishShortName: "Saint Barthélemy",
    iso2Code: "BL",
    iso3Code: "BLM",
    id: 652
  },
  {
    englishShortName: "Saint Helena, Ascension and Tristan da Cunha",
    iso2Code: "SH",
    iso3Code: "SHN",
    id: 654
  },
  {
    englishShortName: "Saint Kitts and Nevis",
    iso2Code: "KN",
    iso3Code: "KNA",
    id: 659
  },
  {
    englishShortName: "Saint Lucia",
    iso2Code: "LC",
    iso3Code: "LCA",
    id: 662
  },
  {
    englishShortName: "Saint Martin (French part)",
    iso2Code: "MF",
    iso3Code: "MAF",
    id: 663
  },
  {
    englishShortName: "Saint Pierre and Miquelon",
    iso2Code: "PM",
    iso3Code: "SPM",
    id: 666
  },
  {
    englishShortName: "Saint Vincent and the Grenadines",
    iso2Code: "VC",
    iso3Code: "VCT",
    id: 670
  },
  {
    englishShortName: "Samoa",
    iso2Code: "WS",
    iso3Code: "WSM",
    id: 882
  },
  {
    englishShortName: "San Marino",
    iso2Code: "SM",
    iso3Code: "SMR",
    id: 674
  },
  {
    englishShortName: "Sao Tome and Principe",
    iso2Code: "ST",
    iso3Code: "STP",
    id: 678
  },
  {
    englishShortName: "Saudi Arabia",
    iso2Code: "SA",
    iso3Code: "SAU",
    id: 682
  },
  {
    englishShortName: "Senegal",
    iso2Code: "SN",
    iso3Code: "SEN",
    id: 686
  },
  {
    englishShortName: "Serbia",
    iso2Code: "RS",
    iso3Code: "SRB",
    id: 688
  },
  {
    englishShortName: "Seychelles",
    iso2Code: "SC",
    iso3Code: "SYC",
    id: 690
  },
  {
    englishShortName: "Sierra Leone",
    iso2Code: "SL",
    iso3Code: "SLE",
    id: 694
  },
  {
    englishShortName: "Singapore",
    iso2Code: "SG",
    iso3Code: "SGP",
    id: 702
  },
  {
    englishShortName: "Sint Maarten (Dutch part)",
    iso2Code: "SX",
    iso3Code: "SXM",
    id: 534
  },
  {
    englishShortName: "Slovakia",
    iso2Code: "SK",
    iso3Code: "SVK",
    id: 703
  },
  {
    englishShortName: "Slovenia",
    iso2Code: "SI",
    iso3Code: "SVN",
    id: 705
  },
  {
    englishShortName: "Solomon Islands",
    iso2Code: "SB",
    iso3Code: "SLB",
    id: 90
  },
  {
    englishShortName: "Somalia",
    iso2Code: "SO",
    iso3Code: "SOM",
    id: 706
  },
  {
    englishShortName: "South Africa",
    iso2Code: "ZA",
    iso3Code: "ZAF",
    id: 710
  },
  {
    englishShortName: "South Georgia and the South Sandwich Islands",
    iso2Code: "GS",
    iso3Code: "SGS",
    id: 239
  },
  {
    englishShortName: "South Sudan",
    iso2Code: "SS",
    iso3Code: "SSD",
    id: 728
  },
  {
    englishShortName: "Spain",
    iso2Code: "ES",
    iso3Code: "ESP",
    id: 724
  },
  {
    englishShortName: "Sri Lanka",
    iso2Code: "LK",
    iso3Code: "LKA",
    id: 144
  },
  {
    englishShortName: "Sudan",
    iso2Code: "SD",
    iso3Code: "SDN",
    id: 729
  },
  {
    englishShortName: "Suriname",
    iso2Code: "SR",
    iso3Code: "SUR",
    id: 740
  },
  {
    englishShortName: "Svalbard and Jan Mayen",
    iso2Code: "SJ",
    iso3Code: "SJM",
    id: 744
  },
  {
    englishShortName: "Sweden",
    iso2Code: "SE",
    iso3Code: "SWE",
    id: 752
  },
  {
    englishShortName: "Switzerland",
    iso2Code: "CH",
    iso3Code: "CHE",
    id: 756
  },
  {
    englishShortName: "Syrian Arab Republic",
    iso2Code: "SY",
    iso3Code: "SYR",
    id: 760
  },
  {
    englishShortName: "Taiwan (Province of China)",
    iso2Code: "TW",
    iso3Code: "TWN",
    id: 158
  },
  {
    englishShortName: "Tajikistan",
    iso2Code: "TJ",
    iso3Code: "TJK",
    id: 762
  },
  {
    englishShortName: "Tanzania, the United Republic of",
    iso2Code: "TZ",
    iso3Code: "TZA",
    id: 834
  },
  {
    englishShortName: "Thailand",
    iso2Code: "TH",
    iso3Code: "THA",
    id: 764
  },
  {
    englishShortName: "Timor-Leste",
    iso2Code: "TL",
    iso3Code: "TLS",
    id: 626
  },
  {
    englishShortName: "Togo",
    iso2Code: "TG",
    iso3Code: "TGO",
    id: 768
  },
  {
    englishShortName: "Tokelau",
    iso2Code: "TK",
    iso3Code: "TKL",
    id: 772
  },
  {
    englishShortName: "Tonga",
    iso2Code: "TO",
    iso3Code: "TON",
    id: 776
  },
  {
    englishShortName: "Trinidad and Tobago",
    iso2Code: "TT",
    iso3Code: "TTO",
    id: 780
  },
  {
    englishShortName: "Tunisia",
    iso2Code: "TN",
    iso3Code: "TUN",
    id: 788
  },
  {
    englishShortName: "Turkey",
    iso2Code: "TR",
    iso3Code: "TUR",
    id: 792
  },
  {
    englishShortName: "Turkmenistan",
    iso2Code: "TM",
    iso3Code: "TKM",
    id: 795
  },
  {
    englishShortName: "Turks and Caicos Islands",
    iso2Code: "TC",
    iso3Code: "TCA",
    id: 796
  },
  {
    englishShortName: "Tuvalu",
    iso2Code: "TV",
    iso3Code: "TUV",
    id: 798
  },
  {
    englishShortName: "Uganda",
    iso2Code: "UG",
    iso3Code: "UGA",
    id: 800
  },
  {
    englishShortName: "Ukraine",
    iso2Code: "UA",
    iso3Code: "UKR",
    id: 804
  },
  {
    englishShortName: "United Arab Emirates",
    iso2Code: "AE",
    iso3Code: "ARE",
    id: 784
  },
  {
    englishShortName: "United Kingdom",
    iso2Code: "GB",
    iso3Code: "GBR",
    id: 826
  },
  {
    englishShortName: "United States Minor Outlying Islands",
    iso2Code: "UM",
    iso3Code: "UMI",
    id: 581
  },
  {
    englishShortName: "United States",
    iso2Code: "US",
    iso3Code: "USA",
    id: 840
  },
  {
    englishShortName: "Uruguay",
    iso2Code: "UY",
    iso3Code: "URY",
    id: 858
  },
  {
    englishShortName: "Uzbekistan",
    iso2Code: "UZ",
    iso3Code: "UZB",
    id: 860
  },
  {
    englishShortName: "Vanuatu",
    iso2Code: "VU",
    iso3Code: "VUT",
    id: 548
  },
  {
    englishShortName: "Venezuela (Bolivarian Republic of)",
    iso2Code: "VE",
    iso3Code: "VEN",
    id: 862
  },
  {
    englishShortName: "Vietnam",
    iso2Code: "VN",
    iso3Code: "VNM",
    id: 704
  },
  {
    englishShortName: "Virgin Islands (British)",
    iso2Code: "VG",
    iso3Code: "VGB",
    id: 92
  },
  {
    englishShortName: "Virgin Islands (U.S.)",
    iso2Code: "VI",
    iso3Code: "VIR",
    id: 850
  },
  {
    englishShortName: "Wallis and Futuna",
    iso2Code: "WF",
    iso3Code: "WLF",
    id: 876
  },
  {
    englishShortName: "Western Sahara",
    iso2Code: "EH",
    iso3Code: "ESH",
    id: 732
  },
  {
    englishShortName: "Yemen",
    iso2Code: "YE",
    iso3Code: "YEM",
    id: 887
  },
  {
    englishShortName: "Zambia",
    iso2Code: "ZM",
    iso3Code: "ZMB",
    id: 894
  },
  {
    englishShortName: "Zimbabwe",
    iso2Code: "ZW",
    iso3Code: "ZWE",
    id: 716
  },
];
