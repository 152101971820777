import React from "react";
import { IClubTypeProps } from "./IClubTypeProps";
import { Card, CardImg } from "react-bootstrap";
import { CLUB_TYPE } from "../../constants/Constants";
import styles from "./ClubType.module.scss";
import kKids from "../../img/search-club/club-icons/kids_logo_left_panel.svg";
import kiwanis from "../../img/search-club/club-icons/kiwanis_logo_left_panel.svg";
import keyClub from "../../img/search-club/club-icons/key_logo_left_panel.svg";
import cki from "../../img/search-club/club-icons/cki_logo_left_panel.svg";
import buildersClub from "../../img/search-club/club-icons/builders_logo_left_panel.svg";
import aktionClub from "../../img/search-club/club-icons/action_logo_left_panel.svg";
import checkmark from "../../img/select-club-type/checkmark_icon.png";
import { useTranslation } from "react-i18next";

const ClubType: React.FC<IClubTypeProps> = (
  {
    cardId,
    clubType,
    isSelected,
    onCardClick
  }) => {
  const {t} = useTranslation();

  function getClubTypeImage(clubType: string) {
    switch (clubType) {
      case CLUB_TYPE.KKids:
        return kKids;
      case CLUB_TYPE.Kiwanis:
        return kiwanis;
      case CLUB_TYPE.KeyClub:
        return keyClub;
      case CLUB_TYPE.Cki:
        return cki;
      case CLUB_TYPE.BuildersClub:
        return buildersClub;
      case CLUB_TYPE.Aktion:
        return aktionClub;
      default:
        return "";
    }
  }

  function getClubTypeDescription(clubType: string) {
    switch (clubType) {
      case CLUB_TYPE.KKids:
        return "select_club_type__k_kids_description";
      case CLUB_TYPE.Kiwanis:
        return "select_club_type__kiwanis_description";
      case CLUB_TYPE.KeyClub:
        return "select_club_type__key_club_description";
      case CLUB_TYPE.Cki:
        return "select_club_type__cki_description";
      case CLUB_TYPE.BuildersClub:
        return "select_club_type__builders_club_description";
      case CLUB_TYPE.Aktion:
        return "select_club_type__aktion_club_description";
      default:
        return "";
    }
  }

  return (
    <>
      <Card id={cardId}
            className={`${styles.kclSelectedClubType}`}
            style={{marginBottom: "25px"}}
            onClick={onCardClick}>
        <Card.Body className="d-flex flex-md-column flex-wrap">
          <CardImg src={getClubTypeImage(clubType)} alt={`club type ${clubType}`}
                   className="align-self-center mt-4 mb-2"
                   style={{padding: 0}}
                   height={"45px"}
          />
          <Card.Text className="mb-4 text-break text-center user-select-none">
            {t(getClubTypeDescription(clubType))}
          </Card.Text>
          <div className={styles.kclCheckIcon}>
            {
              isSelected
                ? <img src={checkmark} alt={`club type ${clubType}`} width={"auto"} style={{padding: 0}}/>
                : <div style={{width: "32px", height: "32px"}}></div>
            }
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClubType;
