import React, { Component } from 'react';
import { Col, FormCheck, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../api/Api";
import { IContactFormSubmitRequest } from "../../../api/api-interfaces/contact-form/IContactFormSubmitRequest";
import { IInterestFormSubmitRequest } from "../../../api/api-interfaces/contact-form/IInterestFormSubmitRequest";
import { COUNTRY, EMAIL_REGEX, PreferredContactMethodInfo } from "../../../constants/Constants";
import { ISO_COUNTRY_LIST } from "../../../constants/countries/CountryList";
import { ISO_AU_STATE_LIST } from "../../../constants/states/au-states/AustraliaStateList";
import { ISO_CA_STATE_LIST } from "../../../constants/states/ca-states/CanadaStateList";
import { ISO_US_STATE_LIST } from "../../../constants/states/us-states/UnitedStatesStateList";
import ImageHelper from "../../../helpers/image-helper/ImageHelper";
import { scrollToFirstError } from "../../../helpers/scroll-into-view-helper/scrollToFirstError";
import { scrollToTop } from "../../../helpers/scroll-into-view-helper/scrollToTop";
import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../helpers/validation-helper/Validations";
import CountryDropdown from "../../country-dropdown/CountryDropdown";
import StateDropdown from "../../state-dropdown/StateDropdown";
import CustomButton from "../buttons/custom-button/CustomButton";
import ContainerPageSize from "../container-page-size/ContainerPageSize";
import LoadingBar from "../loading-bar/LoadingBar";
import RequiredField from "../required-field/RequiredField";
import ValidationMessages from "../validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../vertical-space/VerticalSpace";
import styles from "./ContactFormComponent.module.scss";
import { IContactFormComponentProps } from "./IContactFormComponentProps";
import { IContactFormComponentState } from "./IContactFormComponentState";
import SubmitContactFormNotificationModal
  from "./submit-contact-form-notification-modal/SubmitContactFormNotificationModal";

class ContactFormComponent extends Component<IContactFormComponentProps, IContactFormComponentState> {
  private widget: HTMLDivElement | undefined;

  constructor(props: IContactFormComponentProps) {
    super(props);

    this.state = {
      isInterestForm: undefined,
      clubKeyNumber: undefined,
      clubName: undefined,
      clubType: undefined,
      preferredContactMethod: PreferredContactMethodInfo.Email,

      firstName: '',
      lastName: '',

      email: '',
      emailConfirmation: '',
      phone: '',

      countries: ISO_COUNTRY_LIST,
      selectedCountryIso2Code: COUNTRY.US,
      selectedStateIso2Code: null,
      city: '',
      postalCode: '',
      message: '',

      isFormerKiwanisMember: false,
      isFormerCircleKMember: false,
      isFormerKeyClubMember: false,
      isCurrentKiwanisMember: false,

      recaptchaSiteKey: '',
      isReCaptchaReady: false,
      recaptchaToken: null,
      refreshReCaptcha: null,

      isSubmitContactFormDialogOpen: false,

      isLoading: true,
      excludeKeys: ["FirstName", "LastName", "Email", "EmailConfirmation", "Phone", "PermanentAddressCountryCode",
        "PermanentAddressStateCode", "PermanentAddressCity", "PermanentAddressPostalCode", "Message"
      ],
      validationErrors: null,
      navigate: null
    };
  }

  render() {
    const errors = this.state.validationErrors ?? {};
    const t = this.props.t;

    if (this.state.navigate !== null) {
      return <Navigate to={this.state.navigate}/>;
    }

    return (
      <ContainerPageSize>
        {
          this.state.isLoading || !this.state.isReCaptchaReady ? <LoadingBar/> :
            <div className={"pb-5 mt-1"}>
              <SubmitContactFormNotificationModal
                showDialog={this.state.isSubmitContactFormDialogOpen}
                onConfirm={this.onConfirmSubmitContactFormClick.bind(this)}
              />

              {
                this.state.isInterestForm
                  ?
                  <>
                    <Row>
                      <Col md={8} lg={7} xl={6} xxl={5}>
                        <div className="d-flex flex-wrap justify-content-between align-items-end">
                          <div className={"kcl-heading-fw-600 kcl-ml"}>{t('interest_form__contact_form')}</div>
                          <div className={"d-flex align-items-end kcl-ml kcl-fw-600"}>
                            {
                              this.state.clubType &&
                              <img src={ImageHelper.getGpsPointerByClubType(this.state.clubType)} alt={"gps_pointer"}
                                   height={"27px"} style={{padding: 0}}
                              />
                            }
                            <div className={`${styles.kclClubInfo} text-break`}>
                              {this.state.clubName}
                            </div>
                            <div>({this.state.clubKeyNumber})</div>
                          </div>
                        </div>

                        <hr/>
                        <p className={"kcl-ml"}>{t("interest_form__thank_you")}</p>
                        <div className={"kcl-ml"}>{t('interest_form__i_prefer')}</div>
                      </Col>
                    </Row>
                  </>
                  :
                  <>
                    <Row>
                      <Col md={8} lg={7} xl={6} xxl={5}>
                        <div className={"kcl-heading-fw-600 kcl-ml"}>{t('contact_form__contact_form')}</div>
                        <hr/>
                        <div className={"kcl-ml"}>{t('contact_form__if_you_would_like_to_be_contacted')}</div>
                        <div className={"kcl-ml"}>{t('contact_form__i_prefer')}</div>
                      </Col>
                    </Row>
                  </>
              }

              <form noValidate onSubmit={(e) => this.onSubmitForm(e)}>
                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    {
                      <div className="d-flex mt-1">
                        <FormGroup controlId="contactBy">
                          <FormCheck
                            id={"email-contact-method"}
                            className={"kcl-ml"}
                            type="radio"
                            aria-label="email-contact-method"
                            label={t('contact_form__email')}
                            value={PreferredContactMethodInfo.Email}
                            checked={this.state.preferredContactMethod === PreferredContactMethodInfo.Email}
                            onChange={() => this.onContactMethodChange(PreferredContactMethodInfo.Email)}
                          />

                          <FormCheck
                            id={"phone-contact-method"}
                            className={"kcl-ml"}
                            type="radio"
                            aria-label="phone-contact-method"
                            label={t('contact_form__phone')}
                            value={PreferredContactMethodInfo.Phone}
                            checked={this.state.preferredContactMethod === PreferredContactMethodInfo.Phone}
                            onChange={() => this.onContactMethodChange(PreferredContactMethodInfo.Phone)}
                          />

                          <FormCheck
                            id={"either-contact-method"}
                            className={"kcl-ml"}
                            type="radio"
                            aria-label="either-contact-method"
                            label={t('contact_form__either_method')}
                            value={PreferredContactMethodInfo.Any}
                            checked={this.state.preferredContactMethod === PreferredContactMethodInfo.Any}
                            onChange={() => this.onContactMethodChange(PreferredContactMethodInfo.Any)}
                          />
                        </FormGroup>
                      </div>
                    }
                  </Col>
                </Row>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <hr/>
                    <div className={"kcl-heading-fw-600"}><RequiredField/>{t('contact_form__required')}</div>
                  </Col>
                </Row>
                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>{t('contact_form__first_name')}:<RequiredField/></FormLabel>
                    <FormControl id={"kcl-first-name"}
                                 name="FirstName" type="text" value={this.state.firstName} size={"sm"}
                                 onChange={this.onFirstNameChange.bind(this)}
                    />
                    <ValidationMessages fieldName="FirstName" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>{t('contact_form__last_name')}:<RequiredField/></FormLabel>
                    <FormControl id={"kcl-last-name"}
                                 name="LastName" type="text" value={this.state.lastName} size={"sm"}
                                 onChange={this.onLastNameChange.bind(this)}
                    />
                    <ValidationMessages fieldName="LastName" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>
                      {t('contact_form__email')}:<RequiredField/>
                    </FormLabel>
                    <FormControl id="email" type="email" name="Email" value={this.state.email} size={"sm"}
                                 onChange={this.onEmailChange.bind(this)}
                    />
                    <ValidationMessages fieldName="Email" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>{t('contact_form__retype_email')}:</FormLabel>
                    <FormControl id="emailConfirmation" type="email" name="EmailConfirmation"
                                 value={this.state.emailConfirmation}
                                 size={"sm"}
                                 onChange={this.onEmailConfirmationChange.bind(this)}
                    />
                    <ValidationMessages fieldName="EmailConfirmation" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>
                      {t('contact_form__daytime_phone')}:
                      {
                        (this.state.preferredContactMethod === PreferredContactMethodInfo.Phone ||
                          this.state.preferredContactMethod === PreferredContactMethodInfo.Any) &&
                        <RequiredField/>
                      }
                    </FormLabel>
                    <FormControl type="text" name="Phone" value={this.state.phone} size={"sm"}
                                 onChange={this.onPhoneChange.bind(this)}
                    />
                    <ValidationMessages fieldName="Phone" errors={errors}/>
                  </Col>
                </Row>

                <div className={"kcl-heading-fw-600"}>{t('contact_form__my_permanent_address')}</div>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <CountryDropdown name="PermanentAddressCountryCode"
                                     size={"sm"}
                                     countries={this.state.countries}
                                     selectedCountryIso2Code={this.state.selectedCountryIso2Code}
                                     onCountryChange={(iso2Code) => this.selectCountry(iso2Code)}
                    />
                    <ValidationMessages fieldName="PermanentAddressCountryCode" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                {
                  (this.state.selectedCountryIso2Code === COUNTRY.US ||
                    this.state.selectedCountryIso2Code === COUNTRY.CA ||
                    this.state.selectedCountryIso2Code === COUNTRY.AU) &&
                  <>
                    <Row>
                      <Col md={8} lg={7} xl={6} xxl={5}>
                        <StateDropdown name="PermanentAddressStateCode"
                                       size={"sm"}
                                       states={this.getStates()}
                                       defaultOptionLabel={t('contact_form__select_state')}
                                       selectedStateIso2Code={this.state.selectedStateIso2Code}
                                       onStateChange={(iso2Code) => this.selectState(iso2Code)}
                        />
                        <ValidationMessages fieldName="PermanentAddressStateCode" errors={errors}/>
                      </Col>
                    </Row>
                    <VerticalSpace size={VerticalSpaceSize.xs}/>
                  </>
                }

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>{t('contact_form__city')}:<RequiredField/></FormLabel>
                    <FormControl id={"kcl-city"} name="PermanentAddressCity" type="text" value={this.state.city}
                                 size={"sm"}
                                 onChange={this.onCityChange.bind(this)}
                    />
                    <ValidationMessages fieldName="PermanentAddressCity" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>{t('contact_form__postal_code')}:<RequiredField/></FormLabel>
                    <FormControl id={"kcl-postal-code"}
                                 name="PermanentAddressPostalCode" type="text" value={this.state.postalCode} size={"sm"}
                                 onChange={this.onPostalCodeChange.bind(this)}
                    />
                    <ValidationMessages fieldName="PermanentAddressPostalCode" errors={errors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <FormLabel>
                      {this.state.isInterestForm ? t('interest_form__message_to_the_club') : t('contact_form__message')}:
                    </FormLabel>
                    <FormControl id={"kcl-message"}
                                 name="Message" as="textarea" type="text" value={this.state.message} size={"sm"}
                                 onChange={this.onMessageChange.bind(this)}
                    />
                    <ValidationMessages fieldName={"Message"} errors={errors}/>
                  </Col>
                </Row>

                <div className={"kcl-heading-fw-600"}>{t('contact_form__i_am_a')}:</div>

                <Row>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <div className={"d-flex justify-content-between align-items-end"}>
                      <div>
                        <FormCheck type="checkbox" id={'FormerKiwanisMember'} className={"kcl-ml kclMtMb"}
                                   name={'FormerKiwanisMember'}
                                   checked={this.state.isFormerKiwanisMember ? this.state.isFormerKiwanisMember : false}
                                   onChange={this.onIsFormerKiwanisMemberChange.bind(this)}
                                   label={(<>{t('contact_form__former_kiwanis_member')}</>)}
                        />
                        <FormCheck type="checkbox" id={'FormerCircleKMember'} className={"kcl-ml kclMtMb"}
                                   name={'FormerCircleKMember'}
                                   checked={this.state.isFormerCircleKMember ? this.state.isFormerCircleKMember : false}
                                   onChange={this.onIsFormerCircleKMemberChange.bind(this)}
                                   label={(<>{t('contact_form__former_circle_k_member')}</>)}
                        />
                        <FormCheck type="checkbox" id={'FormerKeyClubMember'} className={"kcl-ml kclMtMb"}
                                   name={'FormerKeyClubMember'}
                                   checked={this.state.isFormerKeyClubMember ? this.state.isFormerKeyClubMember : false}
                                   onChange={this.onIsFormerKeyClubMemberChange.bind(this)}
                                   label={(<>{t('contact_form__former_key_club_member')}</>)}
                        />
                        <FormCheck type="checkbox" id={'CurrentKiwanisMember'} className={"kcl-ml kclMtMb"}
                                   name={'CurrentKiwanisMember'}
                                   checked={this.state.isCurrentKiwanisMember ? this.state.isCurrentKiwanisMember : false}
                                   onChange={this.onIsCurrentKiwanisMemberChange.bind(this)}
                                   label={(<>{t('contact_form__current_kiwanis_member')}</>)}
                        />
                      </div>
                      <div className={"d-flex justify-content-end d-none d-md-block"}>
                        <CustomButton id={"kcl-submit-button-desktop"}
                                      buttonType={"submit"} variant={"primary"} size={"sm"}
                                      title={t("contact_form__submit")}
                                      styles={{marginRight: "1em"}}
                        />

                        <CustomButton buttonType={"button"} variant="secondary" size={"sm"}
                                      title={t("contact_form__cancel")}
                                      onClick={this.onCancel.bind(this)}
                        />
                      </div>
                    </div>

                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.xs}/>

                <Row className={"d-block d-md-none"}>
                  <Col md={8} lg={7} xl={6} xxl={5}>
                    <div className={"d-flex justify-content-end"}>
                      <CustomButton id={"kcl-submit-button-mobile"}
                                    buttonType={"submit"} variant={"primary"} size={"sm"}
                                    title={t("contact_form__submit")}
                                    styles={{marginRight: "1em"}}
                      />

                      <CustomButton buttonType={"button"} variant="secondary" size={"sm"}
                                    title={t("contact_form__cancel")}
                                    onClick={this.onCancel.bind(this)}
                      />
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
        }
        <div className={"pb-5"}/>
      </ContainerPageSize>
    );
  }


  async componentDidMount() {
    if (!this.widget) {
      this.onLoad(this.props.googleRecaptchaSiteKey);
    }

    let clubKeyNumber = this.props.clubKeyNumber;
    let clubName = this.props.clubName;
    let clubType = this.props.clubType;
    let isInterestForm: boolean = clubKeyNumber !== undefined || clubName !== undefined;

    setTimeout(() => {
      scrollToTop();
    }, 600);

    this.setState({
      isInterestForm: isInterestForm,
      clubKeyNumber: clubKeyNumber,
      clubName: clubName,
      clubType: clubType,
      isLoading: false,
      recaptchaSiteKey: this.props.googleRecaptchaSiteKey
    });
  }


  private onLoad = (recaptchaSiteKey: string): void => {
    // #1 create a wrapper div and add it to the DOM
    let widget = document.createElement('div');
    widget.id = 'g-recaptcha';
    this.widget = document.body.appendChild(widget);

    // #2 render the widget into the wrapper div
    window.grecaptcha.render('g-recaptcha', {
      sitekey: recaptchaSiteKey,
      size: 'invisible'
    });

    // #3 set the isReady flag to true when ready
    window.grecaptcha.ready(() => {
      this.setState({isReCaptchaReady: true});
    });
  };


  private executeCaptcha = (action: string): Promise<string> => {
    if (!this.state.isReCaptchaReady) {
      throw new Error("Captcha can be executed only when it's ready.");
    }

    return window.grecaptcha.execute({action: action});
  };

  componentWillUnmount(): void {
    if (this.widget) {
      document.body.removeChild(this.widget);
      this.widget = undefined;
    }
  }

  private onContactMethodChange(contactMethod: PreferredContactMethodInfo) {
    this.setState({preferredContactMethod: contactMethod});
  }

  private onFirstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'FirstName');
    this.setState({firstName: e.target.value});
  }

  private onLastNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'LastName');
    this.setState({lastName: e.target.value});
  }

  private onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'Email');
    this.setState({email: e.target.value});
  }

  private onEmailConfirmationChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'EmailConfirmation');
    this.setState({emailConfirmation: e.target.value});
  }

  private onPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'Phone');
    this.setState({phone: e.target.value});
  }

  private selectCountry(iso2Code: string) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'PermanentAddressCountryCode');
    this.setState({selectedCountryIso2Code: iso2Code});
  }

  private selectState(iso2Code: string) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'PermanentAddressStateCode');
    this.setState({selectedStateIso2Code: iso2Code});
  }

  private onCityChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'PermanentAddressCity');
    this.setState({city: e.target.value});
  }

  private onPostalCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'PermanentAddressPostalCode');
    this.setState({postalCode: e.target.value});
  }

  private onMessageChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'Message');
    this.setState({message: e.target.value});
  }

  private onIsFormerKiwanisMemberChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({isFormerKiwanisMember: e.target.checked});
  }

  private onIsFormerCircleKMemberChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({isFormerCircleKMember: e.target.checked});
  }

  private onIsFormerKeyClubMemberChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({isFormerKeyClubMember: e.target.checked});
  }

  private onIsCurrentKiwanisMemberChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({isCurrentKiwanisMember: e.target.checked});
  }


  private async onSubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      this.showErrorToastMessage();
      setTimeout(() => {
        scrollToFirstError(errors);
      }, 800);
      return;
    }

    if (this.state.clubKeyNumber) {
      await this.submitInterestForm(this.state.clubKeyNumber);
    } else {
      await this.submitContactForm();
    }
  }


  private async submitInterestForm(clubKeyNumber: string) {
    const recaptchaToken = await this.executeCaptcha(this.props.googleRecaptchaActionNameForInterestForm ?? "");
    this.setState({isLoading: true, recaptchaToken: recaptchaToken});

    let interestFormRequest: IInterestFormSubmitRequest = {
      preferredContactMethod: this.state.preferredContactMethod,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      emailConfirmation: this.state.emailConfirmation,
      phone: this.state.phone,
      permanentAddressCountryCode: this.state.selectedCountryIso2Code,
      permanentAddressStateCode: this.state.selectedStateIso2Code,
      permanentAddressCity: this.state.city,
      permanentAddressPostalCode: this.state.postalCode,
      message: this.state.message,
      isFormerKiwanisMember: this.state.isFormerKiwanisMember,
      isFormerCkiMember: this.state.isFormerCircleKMember,
      isFormerKeyClubMember: this.state.isFormerKeyClubMember,
      isCurrentKiwanisMember: this.state.isCurrentKiwanisMember,
      recaptchaResponse: recaptchaToken,
      clubKeyNumber: clubKeyNumber
    };

    try {
      await Api.submitInterestForm(interestFormRequest);

      window.grecaptcha.reset();

      this.setState({isSubmitContactFormDialogOpen: true, isLoading: false});
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors(this.props.t("interest_form__can_not_submit_interest_form"), err)
      );
    }
  }


  private async submitContactForm() {
    const recaptchaToken = await this.executeCaptcha(this.props.googleRecaptchaActionNameForContactForm ?? "");
    this.setState({isLoading: true, recaptchaToken: recaptchaToken});

    let contactFormRequest: IContactFormSubmitRequest = {
      preferredContactMethod: this.state.preferredContactMethod,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      emailConfirmation: this.state.emailConfirmation,
      phone: this.state.phone,
      permanentAddressCountryCode: this.state.selectedCountryIso2Code,
      permanentAddressStateCode: this.state.selectedStateIso2Code,
      permanentAddressCity: this.state.city,
      permanentAddressPostalCode: this.state.postalCode,
      message: this.state.message,
      isFormerKiwanisMember: this.state.isFormerKiwanisMember,
      isFormerCkiMember: this.state.isFormerCircleKMember,
      isFormerKeyClubMember: this.state.isFormerKeyClubMember,
      isCurrentKiwanisMember: this.state.isCurrentKiwanisMember,
      recaptchaResponse: recaptchaToken
    };

    try {
      await Api.submitContactForm(contactFormRequest);

      window.grecaptcha.reset();

      this.setState({isSubmitContactFormDialogOpen: true, isLoading: false});
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors(this.props.t("contact_form__can_not_submit_contact_form"), err)
      );
    }
  }

  private onCancel() {
    if (this.props.onCancelClick) {
      this.props.onCancelClick();
    }
  }

  private onConfirmSubmitContactFormClick() {
    this.setState({isSubmitContactFormDialogOpen: false});

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick();
    }
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!this.state.firstName || this.state.firstName.trim().length < 1) {
      errors = Validations.setErrors(
        {...errors}, 'FirstName', ['contact_form__fist_name_is_required']
      );
    }

    if (this.state.firstName && this.state.firstName.trim().length > 40) {
      errors = Validations.setErrors(
        {...errors}, 'FirstName', ['contact_form__fist_name_is_too_long']
      );
    }

    if (!this.state.lastName || this.state.lastName.trim().length < 1) {
      errors = Validations.setErrors(
        {...errors}, 'LastName', ['contact_form__last_name_is_required']
      );
    }

    if (this.state.lastName && this.state.lastName.trim().length > 80) {
      errors = Validations.setErrors(
        {...errors}, 'LastName', ['contact_form__last_name_is_too_long']
      );
    }

    if (!EMAIL_REGEX.test(this.state.email)) {
      errors = Validations.setErrors({...errors}, 'Email', ['contact_form__email_is_invalid']);
    }

    if (!this.state.email || this.state.email.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'Email', ['contact_form__email_is_required']);
    }

    if (this.state.email !== this.state.emailConfirmation) {
      errors = Validations.setErrors({...errors}, 'EmailConfirmation', ['contact_form__email_confirmation_does_not_match']);
    }

    if (this.state.preferredContactMethod === PreferredContactMethodInfo.Phone ||
      this.state.preferredContactMethod === PreferredContactMethodInfo.Any) {
      if (!this.state.phone || this.state.phone.trim().length < 1) {
        errors = Validations.setErrors({...errors}, 'Phone', ['contact_form__phone_is_required_when_preferred_contact_method_is_phone_or_either']);
      }
    }

    if (!this.state.selectedCountryIso2Code || this.state.selectedCountryIso2Code.trim().length < 1) {
      errors = Validations.setErrors(
        {...errors}, 'PermanentAddressCountryCode', ['contact_form__country_is_required']
      );
    }

    if ((this.state.selectedCountryIso2Code === COUNTRY.US ||
        this.state.selectedCountryIso2Code === COUNTRY.CA ||
        this.state.selectedCountryIso2Code === COUNTRY.AU) &&
      (!this.state.selectedStateIso2Code || this.state.selectedStateIso2Code.trim().length < 1)) {
      errors = Validations.setErrors(
        {...errors}, 'PermanentAddressStateCode', ['contact_form__state_is_required']
      );
    }

    if (!this.state.city || this.state.city.trim().length < 1) {
      errors = Validations.setErrors(
        {...errors}, 'PermanentAddressCity', ['contact_form__city_is_required']
      );
    }

    if (this.state.city && this.state.city.trim().length > 255) {
      errors = Validations.setErrors(
        {...errors}, 'PermanentAddressCity', ['contact_form__city_is_too_long']
      );
    }

    if (!this.state.postalCode || this.state.postalCode.trim().length < 1) {
      errors = Validations.setErrors(
        {...errors}, 'PermanentAddressPostalCode', ['contact_form__postal_code_is_required']
      );
    }

    if (this.state.postalCode && this.state.postalCode.trim().length > 12) {
      errors = Validations.setErrors(
        {...errors}, 'PermanentAddressPostalCode', ['contact_form__postal_code_is_too_long']
      );
    }

    if (this.state.message && this.state.message.length > 1000) {
      errors = Validations.setErrors({...errors}, 'Message', ['contact_form__message_is_too_long']);
    }

    return errors;
  }

  private showErrorToastMessage() {
    let errorMessage = this.props.t("contact_form__please_check_validation_errors");
    toast.error(errorMessage, {toastId: errorMessage});
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    this.setState({validationErrors: validationErrors, isLoading: false});

    if (validationErrors) {
      let render = Validations.getValidationSummary(validationErrors, this.state.excludeKeys);

      if (render) {
        const customId = "contact-form-custom-id";
        toast.error(render, {toastId: customId});
      }
    }
  }

  private getStates() {
    if (this.state.selectedCountryIso2Code === COUNTRY.US) {
      return ISO_US_STATE_LIST;
    }
    if (this.state.selectedCountryIso2Code === COUNTRY.CA) {
      return ISO_CA_STATE_LIST;
    }
    if (this.state.selectedCountryIso2Code === COUNTRY.AU) {
      return ISO_AU_STATE_LIST;
    }
  }
}

export default withTranslation(['translation', 'countries'])(ContactFormComponent);
