import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import Api from "./api/Api";
import IReCaptchaInstance from "./api/api-interfaces/contact-form/IReCaptchaInstance";
import "./App.scss";
import LoadingBar from "./components/common/loading-bar/LoadingBar";
import Toast from "./components/common/toast/Toast";
import Header from "./components/navbar/Header";
import IAppProps from "./IAppProps";
import IAppState from "./IAppState";
import ContactForm from "./pages/contact-form/ContactForm";
import Home from "./pages/home/Home";
import SearchClub from "./pages/search-club/SearchClub";
import SelectClubType from "./pages/select-club-type/SelectClubType";
import RoutingConstants from "./routes/RoutingConstants";
import { defaultLanguage } from "./services/i18n";
import { loadMapApi } from "./utils/GoogleMapsUtils";
import { loadReCaptchaScript } from "./utils/GoogleRecaptchaUtils";

declare global {
  interface Window {
    grecaptcha: IReCaptchaInstance;
    captchaOnLoad: () => void;
    initMap: () => void;
  }
}

class App extends Component<IAppProps, IAppState> {
  private recaptchaScript: HTMLScriptElement | undefined;
  private googleMapsScript: HTMLScriptElement | undefined;

  constructor(props: IAppProps) {
    super(props);

    this.state = {
      showHeader: true,
      googleRecaptchaSiteKey: "",
      isRecaptchaScriptInjected: false,
      googleRecaptchaActionNameForContactForm: "",
      googleRecaptchaActionNameForInterestForm: "",

      googleApiKey: "",
      isGoogleMapScriptInjected: false
    };
  }

  render() {
    return (
      <>
        {
          !this.state.isRecaptchaScriptInjected || !this.state.isGoogleMapScriptInjected ? <LoadingBar/> :
            <>
              <Toast/>

              {
                this.state.showHeader && <Header/>
              }

              <>
                <Routes>
                  <Route path={RoutingConstants.HOME} element={<Home/>}/>

                  <Route
                    path={RoutingConstants.CONTACT_FORM}
                    element={
                      <ContactForm
                        googleRecaptchaSiteKey={this.state.googleRecaptchaSiteKey}
                        googleRecaptchaActionNameForContactForm={this.state.googleRecaptchaActionNameForContactForm}
                      />
                    }
                  />

                  <Route path={RoutingConstants.SELECT_CLUB_TYPE} element={<SelectClubType/>}/>
                  <Route
                    path={RoutingConstants.SEARCH_CLUB}
                    element={
                      <SearchClub
                        googleRecaptchaSiteKey={this.state.googleRecaptchaSiteKey}
                        googleRecaptchaActionNameForInterestForm={this.state.googleRecaptchaActionNameForInterestForm}
                      />
                    }
                  />
                </Routes>
              </>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    await this.setLanguage();

    let response = await this.getSettings();

    this.setState({
      googleRecaptchaSiteKey: response.googleRecaptchaSiteKey,
      googleRecaptchaActionNameForContactForm: response.googleRecaptchaActionNameForContactForm,
      googleRecaptchaActionNameForInterestForm: response.googleRecaptchaActionNameForInterestForm,
      googleApiKey: response.googleApiKey,
      showHeader: this.props.showHeader
    });

    // define recaptcha the onLoad callback
    window.captchaOnLoad = this.onLoad;
    if (!this.recaptchaScript) {
      this.recaptchaScript = loadReCaptchaScript();
    }

    // define Google Maps the initMap callback
    window.initMap = this.initMap;
    if (!this.googleMapsScript) {
      this.googleMapsScript = loadMapApi(response.googleApiKey);
    }
  }

  componentWillUnmount(): void {
    if (this.recaptchaScript) {
      document.body.removeChild(this.recaptchaScript);
      this.recaptchaScript = undefined;
    }

    if (this.googleMapsScript) {
      document.body.removeChild(this.googleMapsScript);
      this.googleMapsScript = undefined;
    }

    this.setState({isRecaptchaScriptInjected: false});
    this.setState({isGoogleMapScriptInjected: false});
  }

  private async setLanguage() {
    let {i18n, language, showHeader} = this.props;
    if (!showHeader) {
      await i18n.changeLanguage(language ? language : defaultLanguage);
    }
  }

  async getSettings() {
    return await Api.getSettings();
  }

  private onLoad = (): void => {
    this.setState({isRecaptchaScriptInjected: true});
  };

  private initMap = (): void => {
    this.setState({isGoogleMapScriptInjected: true});
  };
}

export default withTranslation()(App);
