import React from "react";
import { HashRouter } from "react-router-dom";
import App from './App';
import { LanguageContext } from "./index";

interface IAppBrowserState {
  pageBaseName: string | undefined;
}

interface IAppBrowserProps {
  showHeader: boolean;
  pageBaseName: string | undefined;
}

export default class AppBrowser extends React.Component<IAppBrowserProps, IAppBrowserState> {

  constructor(props: IAppBrowserProps) {
    super(props);

    let pageBaseName = props.pageBaseName === null ? undefined : props.pageBaseName;

    if (pageBaseName !== undefined && pageBaseName.endsWith('/')) {
      pageBaseName = pageBaseName.substring(0, pageBaseName.length - 2);
    }

    console.log(`Router baseName: ${pageBaseName}`);

    this.state = {
      pageBaseName: pageBaseName,
    };
  }

  render(): React.ReactNode {
    return (
      <HashRouter basename={this.state.pageBaseName}>
        <LanguageContext.Consumer>
          {
            language => <App showHeader={this.props.showHeader} language={language}/>
          }
        </LanguageContext.Consumer>
      </HashRouter>
    );
  }
}




