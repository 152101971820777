import React, { Component } from 'react';
import { ILoadingBarProps } from './ILoadingBarProps';
import Loader from "./loader/Loader";
import styles from './LoadingBar.module.scss';

export default class LoadingBar extends Component<ILoadingBarProps> {
  render() {
    return (
      <div className={styles.loaderContainer}>
        <Loader size={this.props.size} style={this.props.style}/>
      </div>
    );
  }
}
