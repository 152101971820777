import React from "react";
import { IMarkerProps } from "./IMarkerProps";

const Marker: React.FC<google.maps.MarkerOptions & IMarkerProps> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();


  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);


  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);

      marker.addListener("click", () => {
        if (options.onMarkerClick) {
          options.onMarkerClick();
        }
      });

      if (options.isSelected) {
        marker.setAnimation(google.maps.Animation.BOUNCE);

        setTimeout(() => {
          marker.setAnimation(null);
        }, 3000);
      }
    }
    return () => {
      if (marker)
      google.maps.event.clearInstanceListeners(marker);
    };
  }, [marker, options]);

  return null;
};

export default Marker;
