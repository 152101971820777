import { MapProps } from "./MapProps";
import React, { FC, useEffect, useRef, useState } from "react";
import { useDeepCompareEffectForMaps } from "./useDeepCompareEffectForMaps";

const Map: FC<MapProps> = (
  {
    bounds,
    style,
    onIdle,
    children,
    ...options
  }) => {

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  function getPadding(): number {
    let match = window.matchMedia('(min-width: 992px)').matches;
    return match ? 150 : 70;
  }

  useEffect(() => {
    if (map) {
      ["idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }

      if (options.center) {
        map.panTo(options.center);
      }

      if (bounds) {
        map.fitBounds(bounds, getPadding());
      }
    }
  }, [map, onIdle, bounds, options.center]);

  return (
    <>
      <div ref={ref} style={style}/>

      {
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {

            // set the map prop on the child component
            // @ts-ignore
            return React.cloneElement(child, {map: map});
          }
        })
      }
    </>
  );
};

export default Map;
