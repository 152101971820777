export enum VerticalSpaceSize{
    xs,
    small,
    normal,
    large
}

export interface IVerticalSpaceProps {
    size?: VerticalSpaceSize;
}
