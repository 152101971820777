export const mapContainerStyle = {
  width: '100%',
  height: '100%'
};

export const mapContainerOpacityStyle = {
  width: '100%',
  height: '100%',
  opacity: 0.33
};
