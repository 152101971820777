import React from 'react';
import { useGeolocated } from "react-geolocated";

export interface WithGeolocationProps {
  coords: GeolocationCoordinates | undefined;
  isGeolocationAvailable: boolean
  isGeolocationEnabled: boolean
}

export function withGeolocation<Props extends WithGeolocationProps>(Component: React.ComponentType<Props>) {
  return (props: Omit<Props, keyof WithGeolocationProps>) => {
    const {coords, isGeolocationAvailable, isGeolocationEnabled} = useGeolocated();

    return (
      <Component
        {...(props as Props)}
        coords={coords}
        isGeolocationAvailable={isGeolocationAvailable}
        isGeolocationEnabled={isGeolocationEnabled}
      />
    );
  };
}
