export const loadMapApi = (googleMapsApiKey: string) => {
    const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places&c&callback=initMap`;

    const scripts = document.getElementsByTagName('script');

    // Go through existing script tags, and return Google Maps API tag when found.
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mapsURL) === 0) {
            return scripts[i];
        }
    }
    const googleMapScript = document.createElement('script');
    googleMapScript.id = "kcl-google-maps-v3"
    googleMapScript.src = mapsURL;
    googleMapScript.async = true;
    googleMapScript.defer = true;
    document.body.appendChild(googleMapScript);

    return googleMapScript;
};