import React, { Component } from 'react';
import { IValidationMessagesProps } from './IValidationMessagesProps';
import styles from './ValidationMessages.module.scss';
import { withTranslation } from "react-i18next";

class ValidationMessages extends Component<IValidationMessagesProps> {
  render() {
    if (!this.props.errors) {
      return <></>;
    }

    let matchingMessages: string[] = [];

    if (this.props.fieldName !== undefined) {
      matchingMessages = this.props.errors[this.props.fieldName];
    }

    if (matchingMessages === undefined || matchingMessages.length === 0) {
      return <></>;
    }

    return (
      <ul className={`${styles.errorMessage} customUl`}>
        {matchingMessages.map(x => <li key={x}>{`${this.props.t(x)}`}</li>)}
      </ul>
    );
  }
}

export default  withTranslation(['translation'])(ValidationMessages)
