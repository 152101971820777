import i18next, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGE_CODE } from "../constants/Constants";

import translation_en from '../translations/en/translation.json';
import countries_en from '../translations/en/countries.json';
import states_en from '../translations/en/states.json';

import translation_de from '../translations/de/translation.json';
import countries_de from '../translations/de/countries.json';
import states_de from '../translations/de/states.json';

import translation_es from '../translations/es/translation.json';
import countries_es from '../translations/es/countries.json';
import states_es from '../translations/es/states.json';

import translation_fr from '../translations/fr/translation.json';
import countries_fr from '../translations/fr/countries.json';
import states_fr from '../translations/fr/states.json';

import translation_it from '../translations/it/translation.json';
import countries_it from '../translations/it/countries.json';
import states_it from '../translations/it/states.json';

import translation_nl from '../translations/nl/translation.json';
import countries_nl from '../translations/nl/countries.json';
import states_nl from '../translations/nl/states.json';

import translation_ja from '../translations/ja/translation.json';
import countries_ja from '../translations/ja/countries.json';
import states_ja from '../translations/ja/states.json';

import translation_zh_CHT from '../translations/zh-CHT/translation.json';
import countries_zh_CHT from '../translations/zh-CHT/countries.json';
import states_zh_CHT from '../translations/zh-CHT/states.json';

export const resources: Resource = {
  en: {
    translation: translation_en,
    countries: countries_en,
    states: states_en
  },
  de: {
    translation: translation_de,
    countries: countries_de,
    states: states_de
  },
  es: {
    translation: translation_es,
    countries: countries_es,
    states: states_es
  },
  fr: {
    translation: translation_fr,
    countries: countries_fr,
    states: states_fr
  },
  it: {
    translation: translation_it,
    countries: countries_it,
    states: states_it
  },
  nl: {
    translation: translation_nl,
    countries: countries_nl,
    states: states_nl
  },
  ja: {
    translation: translation_ja,
    countries: countries_ja,
    states: states_ja
  },
  zh: {
    translation: translation_zh_CHT,
    countries: countries_zh_CHT,
    states: states_zh_CHT
  },
};

export const defaultLanguage = LANGUAGE_CODE.en;
export const defaultNS = "translation";

i18next
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: defaultLanguage,
    lng: defaultLanguage, // default language - if you're using a language detector, do not define the lng option
    ns: ["translation", "countries", "states"],
    defaultNS,
    resources,
    returnNull: false,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18next;
